@import './_theme-color-palette';
@import './_theme-euro2020';
@import './_theme-light';
@import './_theme-dark';
@import './_theme-whitelabel-light';
@import './_theme-whitelabel-dark';

// ***colors assigning for MB elements***
// NB! This is the place for color- and transparency rules (only selectors which uses the color definitions and variables)
//you find the color in _color-palette.scss, the themes are defined in their own files:
// _theme-dark, _theme-light, _theme-whitelabel, _theme-euro2020 and so on.

* {
  &:focus {
    outline: 0;
    color: var(--ink);
  }
}

body {
  color: var(--ink);
  background-color: var(--themecolor_bgr);

  a, a:hover, a:focus {
    color: var(--ink);
  }
}

body {
  background-image: var(--backgroundImage);
}

.panel {
  background-color: var(--panelBackground);
  border: 1px solid var(--panelBorder);

  .footer {
    background-color: var(--inputField);
    border: 1px solid var(--borderThumb);
  }

  .ui-selected .footer {
    background-color: var(--buttonBackground);
  }
}
.no-access {
  text-align: center;
  padding: 50px;
  font-size: 21px;
  color: var(--readonlyInk);
}

.event-modal {
  &__title {
    text-decoration: underline;
    font-weight: bold;
  }

  &__info-label {
    font-weight: bold;
  }

  &__list {
    list-style: none;
    padding-left: 0px;
  }
}

.info-editor {
  .editor-label {
    font-weight: bold;
  }

  &.last {
    margin-bottom: 15px;
  }
}

.field-editor-Info {
  margin-bottom: 0px;

  .help-block {
    display: none;
  }

  &.last {
    margin-bottom: 15px;
  }
}

.autocomplete-editor {
  &__dropdown {
    margin-top: -15px;

    .select2-results__option {
      border-top: 1px solid var(--btnBorder);
      border-left: 1px solid var(--btnBorder);
      border-right: 1px solid var(--btnBorder);
      background-color: var(--themecolor_bgr);
    }

    .select2-container--default .select2-results__option[aria-selected=true] {
      display: block;
      background-color: var(--btnBorder) !important; // Override hover background on selected
    }

    .select2-results__option:first-child {
      border-top: none;
    }

    .select2-selection--multiple, .select2-dropdown {
      border: none;
    }
  }

  .select2-dropdown {
    position: relative;
  }

  .select2-container {
    // Need to use important here since we need to override element style
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
  }

  .select2-selection {
    display: none;
  }
}

.event-modal {
  position: relative;
}

.searchhappening-editor {
  cursor: pointer;
}

.daterangepicker {
  $active-color: var(--appColor);
  $background-color: var(--panelBackground);
  $selected-background-color: var(--selectedAppColor);
  $hover-background-color: var(--themecolor_bgr);
  $hover-ranges-background-color: var(--themecolor_bgr);
  $active-ranges-background-color: var(--selectedAppColor);
  $light-border-color: var(--panelBackground);
  $darker-border-color: var(--btnBorder);
  $font-color: var(--inkContrast);
  $darker-font-color: grey;
  $disable-background-color: #b3b3b3;

  color: inherit;
  background-color: $background-color;
  border-color: $darker-border-color;

  .drp-buttons .btn {
    font-weight: normal;
  }

  .calendar-table th, .calendar-table td {
    border-radius: 0px;
  }

  .calendar-table {
    table {
      tr {
        background-color: transparent;
      }

      th {
        background-color: transparent;
      }

      td {
        border-radius: 0px;
      }
    }
  }

  &:before, &:after {
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }

  &:after, &:before {
    border-right-color: transparent;
    border-bottom-color: $light-border-color;
    border-left-color: transparent;
  }

  &.drop-up:before {
    border-top-color: #ccc;
  }

  &.drop-up:after {
    border-top-color: $light-border-color;
  }

  .calendar-table .next span, .calendar-table .prev span {
    color: $font-color;
    border-color: black;
  }

  .calendar-table th, .calendar-table td {
    border-color: transparent;
  }

  .calendar-table {
    border-color: $light-border-color;
    background-color: $background-color;
  }

  td.available:hover, th.available:hover {
    background-color: $hover-background-color;
    border-color: transparent;
    color: inherit;
  }

  td.week, th.week {
    color: #ccc;
  }

  td.off, td.off.in-range, td.off.start-date, td.off.end-date {
    background-color: $background-color;
    border-color: transparent;
    color: #585555;
  }

  td.in-range {
    background-color: $selected-background-color;
    border-color: transparent;
  }

  td.active, td.active:hover {
    background-color: $active-color;
    border-color: transparent;
    color: $font-color;
  }

  td.disabled, option.disabled {
    color: #999;
  }

  select.hourselect, select.minuteselect, select.secondselect, select.ampmselect {
    background: $background-color;
    border-color: #eee;
  }

  .calendar-time select.disabled {
    color: #ccc;
  }

  &.show-ranges.single.rtl .drp-calendar.left {
    border-right-color: $darker-border-color;
  }

  &.show-ranges.single.ltr .drp-calendar.left {
    border-left-color: $darker-border-color;
  }

  &.show-ranges.rtl .drp-calendar.right {
    border-right-color: $darker-border-color;
  }

  .ranges li:hover {
    background-color: $hover-ranges-background-color;
  }

  .ranges li.active {
    background-color: $active-ranges-background-color;
    color: $font-color;
  }
}

.tags-list__row {
  border-top: 1px solid var(--hrInk);
}

.tags-list__item.is-editing {
  color: var(--darkInk);
}

.tag-type-active-team.active {
  color: var(--darkInk);
}

.nav.nav-tabs {
  .tag-type-active-team.active a {
    color: var(--darkInk);
  }
}

.tags-event-participants__list-item {
  &:focus, &:focus {
    color: var(--darkInk);

    .tags-event-participants__list-item-icon {
      color: var(--darkInk);
    }
  }
}

.player-controls__camerasource .fa {
  color: var(--ink);
}

.player-controls__camerasource.disabled {
  .fa {
    color: var(--disabledInk);
  }
}

.region-participants {
  .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
    color: var(--darkInk);
  }
}

.tags-section-header__text {
  background-color: var(--panelBackground);
  color: (--ink);
}

.tags-list__item.is-empty {
  color: var(--ink);
  background-color: var(--pagerSelected);
}
.tags-eventclips {
  &__roll-item {
    border: 1px solid var(--btnBorder);
    & + input[disabled]{
      background-color: var(--disabledButton);
      color: var(--disabledInk);
    }
  }
  input#duration{
    background: var(--disabledButton);
  }
  input[readonly]{
    color: var(--readonlyInk);
  }
}

#taggedEventPlayer:not(.readonly) #player .active, #taggedEventPlayer .playerElement.active > div.bodyPart {
  background-color: var(--bodyParts);
}

#taggedEventPlayer:not(.readonly) .playerElement:hover,
#taggedEventPlayer:not(.readonly) .playerElement:hover > div.bodyPart {
  background: var(--bodyParts);
  color: var(--ink);
}

.tags-event-section-header__text {
  background-color: var(--panelBackground);
}

.eventtimeline__controls {
  background-color: var(--tabBackground);
  color: var(--ink);
}

.dashboard .panel {
  table {
    td {
      border-bottom: 1px solid var(--hrTheme);
    }
  }
}

input {
  background-color: var(--inputField);
  color: var(--ink);
  border: 1px solid var(--btnBorder);
}

.btn, .button, button, .btn-default, .homebutton, .mainmenuToggler {
  background: var(--buttonBackground);
  border: 1px solid var(--btnBorder);
  color: var(--ink);

  &:hover {
    color: var(--ink);
  }
}

.progress-bar {
  span.file-title, span.upload-progress {
    color: var(--darkInk);
  }

}

.open .dropdown-toggle.btn-default {
  color: var(--ink);
  background-color: var(--buttonBackground);
  border-color: var(--btnBorder);
}

select, .select2-container--default .select2-selection--single {
  background: var(--themecolor-form);
  border: 1px solid var(--btnBorder);
  color: var(--ink);

  &:hover {
    color: var(--ink);
  }
}

.input-group-addon {
  background-color: var(--themecolor-form);
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: var(--inputField);
}

.btn, .button, button, .btn-default {
  &:hover {
    background-color: var(--hoverButton);
    border-color: var(--hoverButtonBorder);
  }

  &:hover, &:active, &.active{
    background-color: var(--hoverButton);
    border-color: var(--hoverButtonBorder);
  }
  &:focus{
    background: var(--buttonBackground);
    border: 1px solid var(--btnBorder);
    color: var(--ink);
  }
}

.btn-default.workspaceicon, a.workspaceicon {
  border-color: var(--btnBorder) var(--btnBorder) var(--btnBorder) var(--separator);

  .dropdownMainMenu & {
    border-color: var(--btnBorder) var(--btnBorder) var(--btnBorder) var(--btnBorder);
  }
}

.workspace-switcher .workspace-button, a.workspaceicon {
  &:first-child button {
    border-left-color: var(--btnBorder);
  }
}

.jobmonitor .btn-default.workspaceicon {
  border-left-color: var(--btnBorder);
}

.select2-dropdown {
  background-color: var(--buttonBackground);
  color: var(--ink);
}

input.select2-search__field {
  background-color: var(--inputField);
  color: var(--ink);
}

.smartsearch__recentsearches {
  background-color: var(--inputField);
  color: var(--ink);
}

.smartsearch {
  &__form {
    &__search-box {
      &.collections-mode {
        .icon-collections {
          color: var(--appColor);
        }
      }

      &__clear-search {
        color: var(--ink);

        &.disabled {
          color: var(--disabledInk);
        }
      }

      input.disabled {
        color: var(--disabledInk);
      }
    }
  }
}

.form-control {
  background-color: var(--inputField);
  border: 1px solid var(--inputFieldBorder);
  color: var(--ink);
}

img.hive-dark {
  display: none;
}

.mainmenuToggler {
  border-left: 1px solid var(--separator);
}

.dropdownMainMenu-wrap {
  background: var(--menu-background);
  color: var(--ink);
}

.dashboard .dropdownMainMenu {
  border-top-color: var(--mainMenuLine);
}

table {
  tr {
    background-color: var(--panelBackground);

    th {
      background-color: var(--panelBackground);
      border-bottom: 2px solid var(--hrThemeWeak);
    }
  }
}

//tables without inner changes:

.mnw-form__fieldset, .assetinfomodal__field-group, .assetinfomodal__short-info {
  table {
    tr {
      background-color: var(--panelBackground);
      border-bottom: 1px dashed var(--hrThemeWeak);

      td {
        background-color: var(--panelBackground);
      }

      th {
        background-color: var(--panelBackground);
        border-bottom: none;
      }
    }
  }
}

.assetinfomodal__field-group-title .inner {
  background-color: var(--panelBackground);
  color: var(--ink);
}

.media_format-container {
  table tr td {
    background-color: var(--panelBackground);
  }
}

.fa, .fa-info-circle {
  color: var(--ink);

  .poster & {
    color: var(--textWidthBackground);
  }

  .billboard__collapse & {
    color: var(--appColor);
  }
}

.alert {
  .fa, .fa-exclamation-triangle.alert-icon, .fa-info-circle {
    color: var(--darkInk);
  }
}
.editProjectDisallowed {
  .fa-edit:before, .fa-pencil-square-o:before {
    color: var(--disabledInk);
    cursor: not-allowed;
  }
}
#m-boxespullup-pullup {
  .fa.fa-angle-up {
    color: white;
  }

  .fa.fa-angle-down {
    color: var(--ink);
  }
}

.billboard__collapse {
  color: var(--ink);
}

.player-container {
  border: 4px solid var(--buttonBackground);
  overflow: hidden;
}

img.color-icons {
  // @include filter(grayscale, var(--grayScale));
}

.mnw-form__fieldset-title {
  background-color: var(--panelBackground);
}

.mnw-form__fieldset-heading-addon {
  background-color: var(--panelBackground);
}

.header-menu__item a {
  color: var(--ink);
}
.white-label, .company-344001 {
  #wrap.liveevent_supervision {
    .customer-logo::after {
      background-image: var(--logoLesUefa);
    }
  }
}

//tabs
#metadatatabslibrary-tab, #metamodulesport-tab, #metadatatabs-tab, #contexttabs-tab {
  > ul.nav-tabs {
    li {
      background-color: var(--tabBackground);
      border-top: none;
      border-right: none;
      border-bottom: 1px solid var(--hrThemeWeak);

      &:first-child {
        border-left: none;
      }

      &.active, &.active a {
        background-color: var(--tabBackground-active);
        border-top: 1px solid var(--hrThemeWeak);
        border-right: 1px solid var(--hrThemeWeak);
        border-left: 1px solid var(--hrThemeWeak);
      }
    }
  }
}


.nav-tabs {
  border-color: var(--hrTheme);
}

.nav-tabs > li > a {
  border: 1px solid var(--hrTheme);
  color: var(--ink);

  &:hover {
    background-color: var(--tabBackground);
    color: var(--hoverInk);
  }
}

.nav-tabs > li.active > a {
  color: var(--ink);
  background-color: var(--tabBackground);
  border-color: var(--hrTheme);
  border-bottom-color: transparent;

  &:focus {
    background-color: var(--tabBackground);
    color: var(--ink);
    border-color: var(--hrTheme);
    border-bottom-color: transparent;
  }

  &:hover {
    background-color: var(--tabBackground);
    color: var(--hoverInk);
  }
}

#m-boxes-region {
  .nav-tabs > li > a {
    background-color: var(--tabBackground);
  }

  .nav-tabs > li.active > a {
    background-color: var(--buttonBackground);
  }
}

.pull-right.btn-group, .pull-left.btn-group, .btn-group.btn-group-justified {
  & > *, & > .disabled {
    border-right: 1px solid var(--btnSeparator);
  }

  & > *:last-child {
    border-right: 1px solid var(--btnBorder);
  }
  & > .btn:last-child:hover{
    border-right: 1px solid var(--hoverButtonBorder);
  }
  & > button.disabled.btn:last-child{
    border-right: 1px solid var(--disabledButton);
  }
  & > button[disabled].btn:last-child{
    border-right: 1px solid var(--disabledButton);
  }
}
.btn-group {
  button.btn.hide + button.btn:hover {
    border-left: 1px solid var(--hoverButtonBorder);
  }
}
.btn-group .btn:not(:first-child):not(:last-child):hover {
  border-left-color: transparent;
}
.nav > li > a:hover, .nav > li > a:focus {
  background-color: var(--tabBackground);
  border-color: var(--hrTheme);
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  border-top: 1px solid var(--btnBorder);
  border-left: 1px solid var(--btnBorder);
  border-right: 1px solid var(--btnBorder);
}

#player-timecode-container .goto-timecode {
  border: 1px solid var(--btnBorder);
}

.player-controls__audiotracks:after {
  content: var(--audiotracks);
}

//*** tooltips

.introjs-tooltip {
  color: var(--ink);
  background-color: var(--modalBackground);
}

.popover {
  color: var(--popover);
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: var(--disabledButton);
  color: var(--disabledInk);
  border: 1px solid var(--btnBorder);
  box-shadow: none;
}

.form-control[readonly] {
  color: var(--readonlyInk);
}

.btn.disabled, .btn-default[disabled], button[disabled],
.btn-default.disabled,
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.focus,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
  background-color: var(--disabledButton);
  opacity: 1 !important;
  color: var(--disabledInk);
  border-color: var(--disabledButton);
  box-shadow: none;
}

.btn.btn-dark[disabled] {
  color: var(--disabledInk);
  opacity: 1 !important;
}

.btn-dark:hover, .btn-dark:focus:hover {
  color: var(--white);
}

.ofcom-rules-status .text {
  color: var(--ink);
}

#player-controls {
  .control-element {
    color: var(--ink);
    background: var(--buttonBackground);
    // border: 1px solid var(--buttonGroupBorder);
  }

  .player-control-group .player-icon {
    color: var(--ink);
  }
}

#player-timecode-container .player-timecode input.timecode-time-item {
  background-color: var(--inputField);
  color: var(--ink);
}

.mnw-form__fieldset-heading-addon {
  background-color: var(--panelBackground);

}

.notifications-view .listTable tr {
  border-top: 1px dashed var(--hrThemeWeak);
}

.smartsearch-filter .smartsearch__edit-section__table .smartsearch__edit-section__row, .smartsearch__edit-section__form {
  border-bottom: 1px solid var(--hrTheme);
}

.smartsearch__edit-section__group-header-row {
  border-top: 5px solid var(--panelBackground);
}

//revisions
.revisions-region {
  ul {
    li {
      &.revision-list-element {
        & a {
          border-top: 0 !important;
          border-left: 1px solid var(--hrThemeWeak) !important;
          border-right: 1px solid var(--hrThemeWeak) !important;

          &:hover {
            background-color: var(--selectedFieldReview);
            color: var(--ink);
          }
        }
      }

      &:last-child a {
        a {
          border-bottom: 1px solid lightgray;
        }
      }

      &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), ~ .js-create-revision {
        border-left: 1px solid lightgray;

        a {
          background-color: var(--buttonBackground);
          color: var(--ink);
        }

        &.active a {
          background-color: var(--buttonBackground);
          color: #51b9e0 !important;
        }

      }
    }
  }
}
.revisions-container{
  ul.nav-tabs a{
    background-color: var(--tableBackground);
  }
}

.asset-annotations-container .annotation-item .comment {
  border: 1px solid var(--hrTheme);
}

.eventannotation__mark-timestamp, #annotation-form .mark-timestamp {
  border-top: 1px solid var(--btnBorder);
  border-bottom: 1px solid var(--btnBorder);
}

// **** ADEX
.adex-content-area::after {
  border-color: transparent;
  border-left-color: var(--panelBackground);
}

.adex-container #left-content {
  background-color: var(--panelBackground);
  border: 1px solid var(--adexBorder);
}

.adex-content-area::before {
  border-color: transparent;
  border-left-color: var(--panelBorder);
}

.adex-list-table td {
  border-bottom: 1px solid var(--hrTheme);
}

.adex-upload-files__upload-type {
  background: var(--buttonBackground);
  color: var(--ink);
  border: 1px solid var(--btnBorder);
}

//********
.btn-dark, .btn-dark:focus {
  background-color: var(--darkButton);
  color: var(--offwhite);

  .fa, .fa-info-circle {
    color: var(--offwhite);
  }

  &.disabled {
    cursor: not-allowed;

    .fa, .glyphicon {
      color: var(--disabledIcon);
      cursor: not-allowed;
    }
  }
}

.asset-annotations-container .annotation-item .comment {
  background-color: var(--inputField);
}

.right-container .form-control-marked {
  background-color: var(--markedField);
}

#complianceChangeTimeCode {
  border: 1px solid var(--btnBorder);

  input {
    background-color: var(--inputField);
    color: var(--ink);
  }
}

#logmodule .k-detail-cell {
  background-color: var(--panelBackground);
}

#logmodule .detail-header {
  border-top: 1px solid var(--hrTheme);
  border-bottom: 1px solid var(--hrTheme);
}

.ui-component__pullup {
  background-color: var(--mBox);
  border-top: 2px solid var(--panelBorder);

  &-tab > a {
    border: 1px solid var(--btnBorder);
    color: var(--ink);
    background-color: var(--buttonBackground);
    border-left: 1px solid var(--hrTheme);
    border-right: 1px solid var(--hrTheme);
  }

  &-tab.active > a.active, &-tab.active > a.active:focus, &-tab.active > a.active:hover {
    background-color: var(--appColor);
  }

  &-tab-controls {
    background-color: var(--panelBackground);
    border-left: 1px solid var(--borderThumb);
    border-right: 1px solid var(--borderThumb);
  }
}

.js-field:disabled {
  background: var(--disabledField);
}

#eventpanellive .match-clock-section, #eventpanel .match-clock-section {
  background-color: var(--buttonBackground);
}

.eventsearch-right-col th.first-header {
  background-color: var(--buttonBackground);
}

.eventsearch-right-col th {
  background-color: var(--buttonBackground);
}

.event-search__edit-section__row:hover {
  color: var(--inkSelected);
}

.ui-component__pullup.is-opened .ui-component__pullup-toggler {
  background-color: var(--buttonBackground);
  color: var(--ink);
}

.mboxpullup__scroller {
  // background-color: var(--buttonBackground);
  color: var(--ink);
}

.mboxpullup__scroll-wrap::after {
  background: -webkit-linear-gradient(right, var(--buttonBackground), rgba(0, 0, 0, 0));
  background: -o-linear-gradient(right, var(--buttonBackground), rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(right, var(--buttonBackground), rgba(0, 0, 0, 0));
  background: linear-gradient(to right, var(--buttonBackground), rgba(0, 0, 0, 0));
}

.mboxpullup__scroll-wrap::before {
  background: -webkit-linear-gradient(left, var(--buttonBackground), rgba(0, 0, 0, 0));
  background: -o-linear-gradient(left, var(--buttonBackground), rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(left, var(--buttonBackground), rgba(0, 0, 0, 0));
  background: linear-gradient(to left, var(--buttonBackground), rgba(0, 0, 0, 0));
}

#masterApprovalTimeCode {
  input#complianceChangeTimeCode {
    background-color: var(--inputField);
    color: var(--ink);
  }
}

img.inactive_icon {
  @include filter(grayscale, var(--grayScale));
}

img.color-icons {
  display: var(--displayIcons);
}

img.darktheme-icons {
  display: var(--displayIconsDark);
}

hr {
  border-top: 1px solid var(--hrTheme);
}

.iterator {
  //border-top: 1px solid var(--hrTheme);
}

//Mbox
.mboxpullup__box-icon {
  background: var(--mboxOpen) no-repeat;
}

.mboxpullup__box-icon.is-closed {
  background: var(--mboxClosed) no-repeat;
}

.mboxpullup__modal-mbox-box-icon {
  background: var(--mboxOpen) no-repeat;
}

.mbox-form .section {
  border-top: 1px solid var(--hrTheme);
}

//**** Magenta - Blue
#welcomemessage-component {
  .role {
    //color: var(--);
  }
}

.k-datetimepicker {
  span.k-select {
    background-color: var(--buttonBackground);
    color: var(--ink);
  }
}

.eventinfo ul.nav-tabs li.tab-list.active {
  border-bottom: 1px solid var(--panelBackground);
}

.context-menu-list {
  background-color: var(--panelBackground);
  border-color: var(--hrTheme);
}

.context-menu-item {
  background-color: var(--panelBackground);
  color: var(--ink);
}

.context-menu-item.context-menu-disabled {
  background-color: var(--panelBackground);
  color: var(--disabledInk) !important;

  &:hover {
    color: var(--disabledInk);
    &:before{
      color: var(--disabledInk);
    }
  }
}
.context-menu-icon.context-menu-icon--fa.context-menu-disabled::before{
  color: var(--disabledInk);
}

.context-menu-item.context-menu-icon--fa:before {
  color: var(--ink);
}

.context-menu-submenu:after {
  border-color: transparent transparent transparent var(--ink);
}

.context-menu-list {
  li.disabled {
    color: var(--disabledInk);
  }
}

.context-menu-item.context-menu-hover {
  &:not(.context-menu-separator) {
    color: white;

    &.grayed {
      color: var(--disabledInk);
    }
  }

  > a {
    color: white;
  }
}

.context-menu-list {
  .grayed, .grayed a {
    color: var(--disabledInk);

    &:hover {
      color: var(--disabledInk);
    }
  }
}
.context-menu-root {
  ul.context-menu-list.mboxes-title.css-title:empty:after {
    color: var(--disabledInk);
  }
}

.smartsearch__form, * {
  input[type="radio"][disabled] + label {
    color: var(--disabledInk);
  }
}

.library_app {
  .context-menu-item {
    &:not(.context-menu-separator):hover {
      background-color: var(--appColor);

      a.attachment-download i.fa.fa-trash {
        color: white;
      }
    }
  }

  .context-menu-item.context-menu-hover {
    &:not(.context-menu-separator) {
      background-color: var(--appColor) !important;

      > span a i.fa.fa-download {
        color: white;
      }

      > span .attachment-download__title {
        color: white;
      }
    }
  }

  span.attachment-delete {
    a i.fa.fa-trash, a i.fa.fa-download {
      color: var(--ink);
    }

    &:hover {
      > a i.fa.fa-trash, > a i.fa.fa-download {
        color: white !important;
      }
    }
  }
}

.assetgroups .nav-tabs > li > a {
  background-color: var(--buttonBackground);
  color: var(--ink);
}

.assetgroups .dropdown__ul li a {
  color: var(--ink) !important;
}

.inline-tabs__tab {
  border-top: 1px solid var(--hrTheme);
  border-left: 1px solid var(--hrTheme);
  color: var(--tabInk);
}

.inline-tabs__tab.cliplist-tab {
  color: var(--tabInk);

  &.active {
    color: var(--inkSelected);
  }
}

.create-cliplist {
  color: var(--ink);
}

// *****
// **********************************************************************************
.dropdown-menu {
  background-color: var(--buttonBackground);
  color: var(--ink);

  & > li > a {
    color: var(--ink);
  }
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background: var(--appColor);
}

.well {
  background-color: var(--buttonBackground);
}

legend {
  color: var(--ink);
}

//select2:
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: var(--buttonBackground);
  border: 1px solid var(--btnBorder);
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--hoverBackground);
  color: var(--ink);
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: var(--ink);

  .select2-container--disabled & {
    color: var(--disabledInk);
  }
}

.select2-container--default .select2-selection--multiple {
  background-color: var(--inputField);
  border: 1px solid var(--inputFieldBorder);
}

.select2-selection--multiple, .select2-dropdown {
  border: 1px solid var(--hrTheme);
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  color: var(--disabledInk);
  background-color: var(--shadedButton);
}

//kendo:
.k-autocomplete.k-state-hover, .k-picker-wrap.k-state-hover, .k-numeric-wrap.k-state-hover, .k-dropdown-wrap.k-state-hover {
  background-color: var(--buttonBackground);
}

.k-dropdown .k-dropdown-wrap {
  background: var(--inputField);

  .k-input {
    color: var(--ink);
  }
}

.k-autocomplete.k-state-default, .k-picker-wrap.k-state-default, .k-numeric-wrap.k-state-default, .k-dropdown-wrap.k-state-default {
  background-color: var(--buttonBackground);
  border-color: var(--btnBorder);
}

.k-dropdown .k-input, .k-dropdown .k-state-focused .k-input, .k-menu .k-popup, .k-dropdown .k-state-hover, .k-dropdown .k-state-hover .k-input {
  color: var(--ink);

  &:hover {
    color: var(--ink);
  }
}

.duration {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--inputField) inset !important;
    -webkit-text-fill-color: var(--ink) !important;
  }
}

.k-header {
  color: var(--ink);
}

.calendar {
  &__filter-buttons {
    button {
      border: 1px solid var(--btnBorder);
      color: var(--ink);
      background-color: var(--buttonBackground);
    }
  }
}

.firstairdate {
  .k-state-default .k-select {
    background-color: var(--buttonBackground);
  }
}

.k-block, .k-widget, .k-input, .k-textbox, .k-group, .k-content, .k-header, .k-editable-area, .k-separator, .k-colorpicker .k-i-arrow-s, .k-textbox > input, .k-autocomplete, .k-dropdown-wrap, .k-toolbar, .k-group-footer td, .k-grid-footer, .k-footer-template td, .k-state-default, .k-state-default .k-select, .k-state-disabled, .k-grid-header-wrap, .k-grid-header, .k-grid td, .k-grid td.k-state-selected, .k-grid-footer-wrap, .k-pager-wrap, .k-pager-wrap .k-link, .k-pager-refresh, .k-grouping-header, .k-grouping-header .k-group-indicator, .k-panelbar > .k-item > .k-link, .k-panel > .k-item > .k-link, .k-panelbar .k-panel, .k-panelbar .k-content, .k-calendar th, .k-slider-track, .k-splitbar, .k-dropzone-active, .k-tiles, .k-toolbar, .k-tooltip, .k-editor-button .k-tool-icon, .k-upload-files {
  border-color: var(--btnBorder);

  #searchlist.panel & {
    border-color: var(--separator) !important;
  }

  #searchlist.panel .k-pager-sizes.k-label & {
    border-color: var(--btnBorder);
  }
}

.k-list-container {
  background-color: var(--btnBorder);
  color: var(--ink);
}

.k-pager-wrap .k-widget, .k-pager-wrap {
  background-color: var(--buttonBackground);
  color: var(--ink);
}

.k-icon {
  color: var(--ink);
  filter: brightness(var(--brightness));
  border-color: transparent;
}

.k-icon, .k-sprite {
  // background-color: var(--panelBackground);
}

.k-pager-wrap > .k-link .k-icon {
  filter: brightness(var(--brightness));
}

#searchgrid .k-state-selected, #searchlist .k-state-selected, #searchworklist .k-state-selected {
  color: var(--inkSelected);
}

#searchgrid .k-state-selected, #searchlist .k-state-selected, #searchworklist .k-state-selected {
  background-color: var(--pagerSelected);
}

.k-state-selected {
  color: var(--inkSelected);
}

#cliplist .k-state-focused, #cliplist .k-state-selected {
  color: var(--inkSelected);
  background-color: var(--pagerSelected);
}

.k-block, .k-widget, .k-popup, .k-content, .k-dropdown .k-input {
  color: var(--ink);
}

.k-grid td {
  border-bottom: 1px dashed var(--tableHelpStripe) !important;
}

#searchgrid .k-grid .k-alt, #searchgrid .k-grid .k-separator, #left-region .k-grid .k-alt, #left-region .k-grid .k-separator {
  background-color: var(--tableBackground);
}

#searchworklist .k-grouping-row {
  background-color: var(--tableBackground);
}

.review {
  #searchworklist {
    .k-state-selected {
      td {
        background-color: var(--pagerSelected);
      }
    }
  }
}
.liveevent_supervision_app {
  #searchlist {
    .k-state-selected {
        background-color: var(--pagerSelected);
    }
  }
  .workspaceicon.active{
    color: var(--appLesDark);
  }
}

#center-region, #left-region {
  .k-grid {
    .k-separator, tr {
      background-color: var(--tableBackground);

      &.k-state-selected, &.logmodule-selected {
        background-color: var(--pagerSelected);
      }
    }
  }
}

#center-region, #left-region {
  .k-grid {
    thead.k-grid-header, .k-header {
      background-color: var(--buttonBackground);
      color: var(--ink);
      border-color: transparent;
    }
  }
}

.k-link:link, .k-link:visited, .k-nav-current.k-state-hover .k-link {
  color: var(--ink);
}

.smartsearch__edit-section .k-picker-wrap, .smartsearch__edit-section .k-numeric-wrap, .smartsearch__edit-section .k-dropdown-wrap {
  background-color: var(--datePicker);
}

.smartsearch__form__quick-search {
  border-bottom: 1px solid var(--hrTheme);
}

#cliplist, table {
  .k-state-focused .k-state-selected {
    color: var(--hoverInk);
  }
}

.table thead > tr > th, .table tbody > tr > th, .table tfoot > tr > th, .table thead > tr > td, .table tbody > tr > td, .table tfoot > tr > td {
  border-top: 1px solid var(--hrTheme);
}

.active-row, .cliplist-overview__row.active-row {
  color: var(--inkSelected);
  background-color: var(--appColor);
}

.list-group-item {
  background-color: var(--inputField);
  color: var(--inkSelected);
  border: 1px solid var(--inputFieldBorder);
}

a.list-group-item {
  &:hover, &:focus {
    background-color: var(--buttonBackground);
    border: 1px solid var(--inputFieldBorder);
  }
}

#eventsearch .eventsearch__roll-group .input-group-addon {
  background-color: var(--buttonBackground);
}

.eventinfo ul.nav-tabs li.tab-list {
  border: 1px solid var(--hrTheme);

  &:first-child {
    border-right: 1px solid var(--hrTheme);
  }
}

.input-group-addon {
  border: 1px solid var(--btnBorder);
}

/*.input-group-btn{
  button {
    i, i.fa, i.fa:focus {
      color: var(--ink);
    }
  }
}*/

#searchworklist .k-state-selected, table .k-state-selected {
  color: var(--inkSelected);
  background-color: var(--pagerSelected);
}

a.list-group-item {
  color: var(--ink);
}

.k-pager-wrap {
  background-color: var(--panelBackground);
  background-image: none;
}

.k-block, .k-widget {
  background-color: var(--panelBackground);
}

.k-calendar th {
  background-color: var(--buttonBackground);
}

span.k-icon.k-i-arrow-w:after, span.k-icon.k-i-arrow-e:after {
  color: var(--ink);
}

.k-block, .k-header, .k-grid-header, .k-toolbar, .k-grouping-header, .k-button, .k-draghandle {
  background-color: var(--buttonBackground);
}

.k-button {
  color: var(--ink);
}

.k-grouping-row td, td.k-group-cell {
  background-color: var(--tableBackground);
  color: var(--ink);
}

.k-state-selected, .k-state-selected:link, .k-state-selected:visited, .k-list > .k-state-selected, .k-list > .k-state-highlight, .k-panel > .k-state-selected, .k-button:active, .k-ghost-splitbar-vertical, .k-ghost-splitbar-horizontal, .k-draghandle.k-state-selected:hover, .k-scheduler .k-scheduler-toolbar .k-state-selected, .k-marquee-color {
  background-image: none;
  background-color: var(--selectedFieldLibrary);
  color: var(--inkSelected);
  border-color: var(--hrTheme);
}

.k-calendar .k-link.k-nav-fast {
  color: var(--ink);

  &.k-state-hover {
    color: var(--hoverInk);
    background-color: var(--buttonBackground);
  }
}

.k-animation-container {
  background-color: var(--panelBackground);
}

.k-calendar .k-footer .k-nav-today {
  background-color: var(--buttonBackground);
  color: var(--ink);

  &:hover {
    background-color: var(--panelBackground);
    color: var(--hoverInk);
  }
}

#customer_registration-page {
  .k-content, .k-editable-area, .k-panelbar > li.k-item, .k-panel > li.k-item, .k-tiles {
    background-color: transparent;
  }
}

.k-header, .k-grid-header, .k-toolbar, .k-dropdown-wrap, .k-picker-wrap, .k-numeric-wrap, .k-grouping-header, .k-pager-wrap, .k-textbox, .k-button, .k-draghandle, .k-autocomplete, .k-state-highlight, .k-tabstrip-items .k-item {
  background-image: none;
}

.k-button:hover, .k-button:focus, .k-button.k-state-focused, .k-textbox:hover, .k-state-hover, .k-state-hover:hover, .k-pager-wrap .k-link:hover, .k-other-month.k-state-hover .k-link, div.k-imagebrowser-dropzone em, .k-draghandle:hover {
  background-image: none;
}

.k-group, .k-toolbar, .k-grouping-header, .k-group-footer td, .k-grid-footer, .k-footer-template td, .k-widget .k-status, .k-calendar th, .k-dropzone-hovered, .k-widget.k-popup {
  background-color: var(--buttonBackground);
}

.k-content, .k-editable-area, .k-panelbar > li.k-item, .k-panel > li.k-item, .k-tiles {
  background-color: var(--panelBackground);
}

.k-calendar-container.k-group {
  border-color: var(--btnBorder);
}

.k-input, input.k-textbox, textarea.k-textbox, input.k-textbox:hover, textarea.k-textbox:hover, .k-textbox > input, .k-multiselect-wrap {
  background-color: var(--inputField);
  color: var(--ink);
}

.smartsearch__form-date-fields {
  .k-icon {
    background-color: transparent;
  }
}

.smartsearch__form__match-type {
  border-top: 1px solid var(--hrTheme);
}

.smartsearch__list-section .t-row, .smartsearch__list-table .t-row {
  border-bottom: 1px dashed var(--tableHelpStripe);
}

.smartsearch__list-table .t-row .t-cell.loaded {
  color: var(--appColor);
}

.smartsearch__edit-section .k-picker-wrap, .smartsearch__edit-section .k-numeric-wrap, .smartsearch__edit-section .k-dropdown-wrap {
  background-color: var(--inputField);
}

.projects-filter__item, .group-filter__item {
  border-bottom: 1px dashed var(--tableHelpStripe);
}
#projects .active .projects-filter__item a {
  color: var(--appHighlight);
}
#projects .projects-filter__item a {
  &:hover{
    color: var(--appHighlight);
  }
}
#groups-collapse-content .active .group-filter__item a {
  color: var(--appHighlight);
}
#groups-collapse-content .group-filter__item a {
  &:hover {
    color: var(--appHighlight);
  }
}

.group-filter__item-anchor, .group-filter__item-edit, .group-filter__item-edit-disabled {

  &:hover {
    color: var(--appColor);
  }
}

// modal
.mnw-modal .modal-header {
  border-bottom: 1px solid var(--hrTheme);
}

.modal-content {
  background-color: var(--panelBackground);
}

button.close {
  color: var(--ink);
}

.introjs-button {
  border: 1px solid var(--btnBorder);
}

.introjs-button.introjs-skipbutton:hover {
  background-color: var(--hoverButton);
  border: 1px solid var(--btnBorder);
}

.calendar__label__value {
  color: var(--inkCalendar);
}

.calendar__day__hatched-dates {
  background-image: var(--calendarBackground);
}

.calendar__day, .calendar__weekday {
  border: 1px solid var(--discreteBorder);
}

.calendar__label__value.today {
  background-color: var(--appColor);
}

.calendar__filter-buttons button {
  background-color: var(--buttonBackground);
}

.collections .sub-collection__item__label {
  color: var(--textWidthBackground);
}

.assetgroups .nav-tabs > li > a .caret {
  border-top-color: var(--ink);
  border-bottom-color: var(--ink);
}

.adex-form__fieldset-title {
  background-color: var(--panelBackground);
}

.adex-form__fieldset-heading-addon {
  background-color: var(--panelBackground);
}

.selected-btn {
  color: var(--appColor);
}

#eventsearch .event-search__tabs .inline-tabs__tab.active {
  color: var(--inkSelected);
}

.inline-tabs__tab {
  &:hover {
    color: var(--inkSelected);
  }
}

.nav.nav-tabs li.active a {
  color: var(--inkSelected);
}

.header-menu__item.disabled, .header-menu__item.disabled:hover {
  color: var(--disabledInk);

  .fa, .fa:hover {
    color: var(--disabledInk);
  }
}

.groupsModal {
  .btn-highlighted {
    color: var(--appColor);
  }

  .disabled {
    color: var(--disabledInk);
  }
}

.tags-soccer-event-participants li.substituted {
  background-color: var(--substBackground);

  &:hover {
    color: var(--ink);
  }
}

.assetmetabatchedit {
  &__field-key[readonly] {
    background: var(--inputField);
    color: var(--ink);
  }
}

.mbox-usage {
  border-top: 1px dotted var(--hrTheme);

  &--title {
    background-color: var(--panelBackground);
  }
}

.properties__raceinfo-list li.even {
  background-color: var(--evenRow);
}

#center-region .k-grid table tr, #left-region .k-grid table tr {
  border-bottom: 1px dashed var(--tableHelpStripe);
}

.app_icon {
  &.enabled {
    p {
      color: var(--strongInk);
    }
  }

  &.disabled {
    p {
      color: var(--disabledInk);
    }
  }
}

#icon_dashboard {
  content: var(--mediabankIcon);
}

//eventannotation
.eventannotation-action-element {
  .title {
    .update-button {
      color: var(--markInOutColour);
    }
  }

  .action-button {
    border: 1px solid var(--btnBorder);
    color: var(--eventButtonText);

    &.error {
      border: 2px solid #b94a48;
    }
  }

  .activated {
    border: 1px solid var(--activatedAnnotationActionColor);
    color: var(--eventButtonText);
  }

  .disabled:not(.action-button) {
    border: 1px dashed var(--btnBorder);
  }

  .disabled {
    color: var(--disabledInk);
  }

  .btn {
    .activated-icon {
      background-color: var(--darkCircle);
    }

    &.activated {
      .activated-icon {
        background-color: var(--activatedAnnotationActionColor);
      }
    }

    .activate {
      background-color: var(--backgroundEventAnnotationLeft);
    }

    &.error {
      .activate {
        background-color: #b94a48;
      }
    }
  }
}

.vjs-control-bar:focus, #framesPlayer:focus{
  color: #ffffff;
}

@font-face {
  font-family: 'BridgeType-Regular';
  src: url('/fonts/euro2020-BridgeRegular/BridgeType-Regular.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

//common colors for all themes... except Euro:
.poster-info .info-text__text {
  color: #e5e5e5;
}
.player-controls__camerasource-list{
  .fa.fa-check {
    color: #3d3d3d;
  }
}
