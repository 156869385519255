//Dark White LAbel UEFA theme
//Colors are brought from _themes-color-palette

body.white-label.ThemeDark, body.company-344001.ThemeDark {
  --backgroundImage: var(--noneBackgroundImage);
  --uefaHiveDark: var(--blueDarkTheme);
  --logoLesUefa: url("../../images/logo-LES-darktheme.png");
  color: var(--lightInk);

  img.hive-dark {
    display: block;
  }

  .customer-logo__img.cust-lg {
    display: none;
  }

  &.library_app {
    --appColor: var(--blueDarkTheme);
    --pagerSelected: var(--blueDarkTheme);
    --appHighlight: var(--blueDarkTheme);
  }

  &.adex {
    --appColor: var(--blueDarkTheme);
    --pagerSelected: var(--blueDarkTheme);
  }

  &.review_app {
    --appColor: var(--blueDarkTheme);
    --pagerSelected: var(--blueDarkTheme);
  }

  &.admin {
    --appColor: var(--blueDarkTheme);
    --pagerSelected: var(--blueDarkTheme);
  }

  &.dashborad_app {
    --appColor: var(--blueDarkTheme);
    --pagerSelected: var(--blueDarkTheme);
  }

  &.compliance_app {
    --appColor: var(--blueDarkTheme);
    --pagerSelected: var(--blueDarkTheme);
  }
}

