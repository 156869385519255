// the basic file for color definitions
:root {
  --activatedAnnotationActionColorDark: #eea425;
  --activatedAnnotationActionColorHive: #6fc6da;
  --activatedAnnotationActionColorLight: #eea425;
  --adexBorderDark: #50595C;
  --appColorHive: #009bb4;
  --appLesDark: #3c8c83;
  --appLesLight: #d2efec;
  --audiotracksDark: url(../../icons/generic/audiotrackDark.svg);
  --audiotracksLight: url(../../icons/generic/audiotrack.svg);
  --backgroundEventAnnotationLeftLight: #d3d3d3;
  --backgroundEventAnnotationLeftDark: #000000;
  --black: #000;
  --blueDarkHive: #063676;
  --blueDarkTheme: #009bb4;
  --blueLightHive: #D0ebf6;
  --blueTransparent: #009bb49e;
  --bodyPartsDark: #000;
  --bodyPartsLight: #3d3d3d;
  --borderThumbDark: #2f3436;
  --borderThumbLight: #ccc;
  --buttonDark: #50595C; //button-background
  --coalDark: #50595C;
  --calendarBackgroundDark: linear-gradient(-45deg, transparent 46%, #ddd 49%, #232222 51%, transparent 55%);
  --calendarBackgroundLight: linear-gradient(-45deg, transparent 46%, #ddd 49%, #ddd 51%, transparent 55%);
  --calendarBackgroundWhite: linear-gradient(-45deg, transparent 46%, #ddd 49%, #fff 51%, transparent 55%);
  --darkBorders: #aaaaaa;
  --darkButtonDark: #50595C;
  --darkButtonLight: #3d3d3d;
  --darkCircleDark: #51595c;
  --darkCircleLight: #fafafa;
  --darkerLine: #979797; //button, panel - border
  --darkestGround: #282828; //#0a0a0a;
  --darkInk: #3d3d3d;
  --darkInputField: #0B0D0E;
  --darkInputFieldInk: #fff;
  --darkOverlay: #383838; //#1f2324;
  --darkSeparator: #666567;
  --darkThemeBorder: #737677;
  --datePickerDark: #8a8686;
  --datePickerLight: #fff;
  --disabledButtonDark: #44484a;
  --disabledButtonLight: #eee;
  --disabledFieldDark: #757778;
  --disabledFieldLight: #eee;
  --disabledInkDark: #757373;
  --disabledInkLight: #aaa8a8;
  --discreteBorderLight: #e5e5e5;
  --discreteBorderDark: #a09e9e;
  --displayIconsNo: none;
  --displayIconsYes: block;
  --doGray: 100%;
  --dontGray: 0%;
  --dropDark: #979797;
  --euro2020Theme: #009bb4;
  --euroGray: #dedbdb;
  --euroTab: #efefef;
  --euroTabActive: #fafafa;
  --evenRowDark: #50595c;
  --evenRowLight: #f3f3f3;
  --eventButtonTextDark: #6fc6da;
  --eventButtonTextLight: #6fc6da;
  --grayTab: #525859;
  --hoverInkDark: #FFFFFF;
  --hoverInkLight: #3d3d3d;
  --hrThemeDark: #787a7a;
  --hrThemeLight: #d3d3d3;
  --hrThemeWeakDark: #4e4c4c;
  --hrThemeWeakLight: #efefef;
  --inputFieldBorderDark: #545757;
  --inputFieldBorderLight: #d3d3d3;
  --inputFieldDark: #282828;
  --inputFieldLight: #FFFFFF;
  --labeledBackgroundImage: url(/images/hive-background.png);
  --labeledEuroBackgroundImage: url(/images/euro2020-background.png);
  --lightGray: #d3d3d3;
  --lightInk: rgba(255, 255, 255, 0.6);
  --lightLine: #fafafa;
  --lightThemeBorder: #d3d3d3;
  --magentaDefaultTheme: #ff0051;
  --markedFieldDark: #000;
  --markedFieldLight: #fdfad6;
  --markInOutColour: #6fc6da;
  --mboxDark: rgba(80, 89, 92, 0.9);
  --mboxLight: rgba(255, 255, 255, 0.9);
  --mboxClosedWhite: url(/icons/icon_destinations_closed.png);
  --mboxClosedDark: url(/icons/icon_destinations_open_dark.png);
  --mboxOpenWhite: url(/icons/icon_destinations_open.png);
  --mboxOpenDark: url(/icons/icon_destinations_open_dark.png);
  --mediabankIconLight: url(/images/apps/icon_dashboard2.svg);
  --mediabankIconDark: url(/images/MB_pink_invert_dark_theme_makes.png);
  --modalDarkBackground: #2F3436;
  --noneBackgroundImage: none;
  --notSoBright: 50%;
  --offwhite: #fafafa; //background - text
  --popoverInk: #3d3d3d;
  --readOnlyDark: #9e9c9c;
  --readOnlyLight: #838181;
  --scrollBackgroundDark: #44484a;
  --selectedDark: #393838;
  --selectedFieldComplianceLight: #FFC5C2;
  --selectedFieldLibraryDark: var(--buttonDark);
  --selectedFieldLibraryLight: #fdeacb;
  --selectedFieldReviewDark: #34393b;
  --selectedFieldReviewLight: #BFE2F2;
  --shadedButtonDark: #50595C;
  --shadedButtonLight: #eee;
  --strongInkDark: #000;
  --strongInkLight: #fff;
  --substBackgroundDark: #311e12;
  --substBackgroundLight: #fccfd6;
  --tabInkLight: #808080;
  --tableStripeDark: #121124;
  --tableStripeLight: #f9f9f9;
  --tableThBorderDark: #777979;
  --tableThBorderLight: #d3d3d3;
  --textWidthBackground: #d3d3d3;
  --thirdLevelDark: #454343; //#2f3436 ;
  --thirdLevelLight: #fff;
  --thirdLevelTheme: #404040; //#2f3436;
  --thumbLightFooter: #fff;
  --thumbDarkFooter: #18191a;
  --transparent: transparent;
  --veryBright: 150%;
  --veryLightGray: #e5e5e5;
  --veryVeryLightGray: #f7f7f7;
  --white: #fff;
  --whiteTab: #fff;
  --whiteTransparent: #fffffff1;

  --adexColor: #b8d545;
  --adminColor: #eb5d33;
  --complianceColor: #ef4136;
  --dashboardColor: #ff0051;
  --libraryColor: #eea425;
  --rnaColor: #51B9E0;

}

