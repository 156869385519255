@import './mixins';
@import '../components/noticebar/css/noticebar';
@import './theme';
$uefa_hive: #063676;
$active-bg-color: #ff0f6c;
body {
  background-color: initial;
}
body.login-pg.uefa-hive, #forgotpwd-page body.uefa-hive, html#changepwd-page body.uefa-hive{

  background-image: url(/images/hive-background.png) !important;

}

.responsive-wrap {
  @media screen {
    white-space: nowrap;
  }
  @media screen and (max-width: 480px) {
    white-space: initial;
  }
}

#mediabankIn30.sliderTextHeading {
  margin-top: 100px;
  @media screen and (max-width: 480px) {
    font-size: 50px;
    line-height: 50px;
    margin-top: 40px;
  }
}

.mobile-align-left {
  margin-left: 40px;
  @media screen and (max-width: 480px) {
    margin-left: 0;
  }
}

.highlight-article {
  background-color: #6DC69D;
  color: #ffffff;
  font-weight: normal;
  font-size: 34px;
  border-radius: 0;
  @media screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.extra-top-space {
  margin-top: 100px;
  @media screen and (max-width: 480px) {
    margin-top: 20px;
  }
}

#forgotPassword {
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;

  #container {
    background-color: #f2f0f0;
    -webkit-font-smoothing: antialiased;
  }

  .btn-red {
    background-color: $active-bg-color;
    border-color: #ff0051;
    width: 100%;
    margin-top: 0px;
    margin-right: -10px;
    color: white;
    @include breakpoint(xsmall) {
      width: 130px;
    }
    &:hover {
      color: #fafafa;
      background-color: #b5004c;
      border-color: #91003d;
    }
  }
  .well-rounded {
    font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
    width: 500px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 35px;
    border-radius: 4px;
    border: 1px solid #c8c8c8;
  }

  .h2-mb {
    font-weight: normal;
  }

  .lead-condensed {
    color: white;
    font-size: 16px;
    font-weight: normal;
  }
}

#top-menu {
  .dropdown-menu {
    width: 150px;
    min-width: 140px;
    padding: 0;
  }
  #accountDropdown {
    background-color: #3d3d3d;
    width: 150px;
    min-width: 140px;
    padding: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(xsmall) {
      width: 130px;
      min-width: 130px;
    }
    .input-group {
      padding-left: 10px;
    }
  }
  #loginDropdown {
    width: 150px;
    min-width: 140px;
    padding: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(xsmall) {
      width: 130px;
      min-width: 130px;
    }
  }
  .input-group {
    .dropdown-text {
      padding: 5px 0;
      &.password {
        border-top: 1px dotted white;
      }
      a {
        color: white;
        text-decoration: none;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
  .password-out, .support-link {
    a {
      color: black;
      text-decoration: none;
      cursor: pointer;
      font-size: 12px;
      margin: 0 auto;
      display: block;
      width: 150px;
    }
  }
  a.account-link::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #ffffff transparent transparent transparent;
    content: "";
    display: inline-block;
    padding-top: 5px;
    margin-left: 7px;
  }
  &.well {
    @include breakpoint(xlarge) {
      padding-right: 0;
    }
    @include breakpoint(xsmall) {
      padding-left: 0px;
      padding-right: 8px;
    }
  }
  .text-center.col-sm-2 {
    padding-right: 0;
  }
  @include breakpoint(xlarge) {
    #accountDropdown {
      margin-left: 13px;
    }
    #loginDropdown {
      margin-left: 20px;
    }
  }
  @include breakpoint(mediumless) {
    .mblogo {
      margin-bottom: 20px;
    }
  }
  .signin {
    background-color: $active-bg-color;
    width: 150px;
    margin-top: 0px;
    margin-right: -10px;
    @include breakpoint(xsmall) {
      width: 130px;
    }
  }
  a.account-link {
    background-color: #3d3d3d;
    width: 150px;
    margin-top: 0;
    @include breakpoint(xsmall) {
      width: 130px;
    }
  }
  button.btn.sign-btn {
    text-transform: inherit;
    color: white;
    background-color: #3d3d3d;
    font-weight: normal;
    width: 150px;
    margin-top: 1px;
  }
}
.alert-danger {
  color: red;
  background-color: white;
  border: 1px solid #ccc;
  font-size: 16px;
  text-align: center;
  p{
    color: red;
  }
}
.neplogo{
  display: block;
  margin: 0 auto;
}
.logoLogIn {
  background-image: url(/images/Invert-MB_pink_tall_makes-life-easier.png);
  width: 236px;
  height: 144px;
  background-repeat: no-repeat;
  background-size: 236px 144px;
  overflow: hidden;
  position: relative;
  background-position: center;
  margin: 40px auto;
}
.loggedInn, .forgotPwd, .changedPwd {
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
  h2{
    color: white;
    text-align: center;
  }
  button{
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 2px;
    height: 48px;
    @include breakpoint(xsmall){
      width: 280px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  select{
    height: 48px;
    @include breakpoint(xsmall){
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.textLogged {
  font-size: 30px;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}
.loggedInName{
  font-size: 24px;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}
.btn-continueWork{
  background-color: rgb(232, 0, 97);
  padding: 6px 10px;
  border-width: 0;
  border-radius: 6px;
  border-style: solid;
  background-size: auto;
  background-repeat: repeat;
  background-position: center center;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  height: 45px;
  color: white;
  margin: 0 auto 20px;
  display: block;
  &:hover{
    color: white;
    background-color: #b5004c;
    border-color: #91003d;
  }
}

body.login-pg, #forgotpwd-page body, html#changepwd-page {
  background-image: url(/images/The-Factory-process.jpg) ;
  background-repeat: no-repeat;
  height: 100%;
  background-size: 100%;
  background-color: #000002;
}

textarea#company_meta_comments::placeholder, input.form-control::placeholder{
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 2px;
  text-align: center;
  padding-top: 0;
}

.btnLogout {
  border-color: rgb(221, 221, 221);
  background-color: rgb(61, 61, 61);
  background-image: none;
  color: rgb(255, 255, 255);
  padding: 6px 10px;
  border-width: 0;
  border-radius: 6px;
  border-style: solid;
  background-size: auto;
  background-repeat: repeat;
  background-position: center center;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  height: 45px;
  &:hover{
    color: white;
  }
}

select#changeRepresentCompanySelect{
  margin-bottom: 20px;
  width: 100%;
  color: rgb(102, 102, 102);
  font-size: 17px;
  font-weight: normal;
  @include breakpoint(xsmall){
    width: 280px;
  }
}
.signIn {
  margin: 0 auto;
  display: block;
  float: none;
  color: white;
  width: 100%;
  max-width: 380px;
  input{
    border-radius: 0;
    max-width: 380px;
    height: 48px;
  }
}
h1.signinText{
  font-size: 36px;
  letter-spacing: 4px;
  font-weight: normal;
}
label.labelpassword, label.labelusername{
  display: none;
}
.forgotPwd, .changedPwd{
  h1, h2{
    font-weight: normal !important;
    letter-spacing: 4px;
    font-size: 36px;
  }
}
.passwordlink {
  a {
    color: #fafafa;
  }
}
.form-group {
  color: #fafafa;
  input.input-lg, input.form-control {
    font-weight: normal;
    font-size: 17px;
    letter-spacing: 2px;
    color: #3d3d3d;
    text-align: center;
    height: 44px;
    border-radius: 3px;
    @include breakpoint(xsmall){
      font-size: 15px;
      max-width: 280px;
      margin:0 auto;
      display: block;
    }
  }
  &.partnerlink{
    color:#3d3d3d;
    a, a:hover{
      color:#3d3d3d;
      text-decoration: none;
    }
  }
}
select.form-control{
  height: 44px;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 2px;
}
@include breakpoint(xsmall) {
  input#username, input#password{
    max-width: 280px;
    margin: 0 auto;
  }
  p.lead-condensed {
    max-width: 280px;
    text-align: center;
  }
  .passwordlink{
    max-width: 280px;
    text-align: center;
    margin-left: 10px;
  }
}
.errorAppSwitch{
  color: #fafafa;
}
#forgotpwd-page{
  .ui-pnotify-history-container{
    display: none;
  }
  .alert{
    display: none;
  }
}
.noaccount{
  text-align: center;
  font-size: 28px;
  font-weight: normal;
  color: #fafafa;
  margin-bottom: 20px;
}
.tryagain{
  height: 48px;
  color: #fafafa;
  background-color: #e80061;
  border-color: #cf0056;
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
  display: block;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 2px;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
}
.forgotPwdError {
  a{
    text-decoration: none;
    p:hover {
      background-color: #b5004c;
      border-color: #91003d;
    }
  }
}
#customer_registration-page, #forgotpwd-page, html#resetpwd-page{

  body{
    background-color: #000105;
    background-image: url(/images/The-Factory-process.jpg);
    background-repeat: no-repeat;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    color: white;
    letter-spacing: 1px;
    background-size: 100%;
  }
  .errorBorder {
    border-color: #FFAAAA;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .passOK {
    color: #009900;
  }

  .passNOTOK {
    color: #990000;
  }

  .form-inline {
    display: inline-block !important;
  }

  .divider {
    height: 1px;
    background: #999;
    margin: 5px 0px 15px 0px;
  }

  #submitFormButton.disabled {
    background-color: #e3e3e3;
    border-color: #b5b5b5;
  }
  fieldset {
    margin-bottom: 20px;
  }

  .form-group {
    .description {
      display: block;
      margin-top: 5px;
      margin-bottom: 10px;
      font-size: 12px;
      padding-left: 5px;
    }

    .checkbox {
      font-size: 14px;
    }

    .description.passNOTOK {
      color: #e51800 !important;
    }

    .description.passOK {
      color: #5cb85c !important;
    }
    p {
      font-size: 1.7rem;
      font-weight: normal;
      color: #fafafa;
      margin: 0 auto 20px;
    }
  }
  .form-title {
    color: #6f6f6f;
  }

  .user {
    padding-right: 3px;
  }

  legend {
    font-size: 16px;
    margin-bottom: 5px;
    color: white;
    border-bottom: 0;
  }
  label, legend {
    font-weight: normal !important;
  }

  .center-panel{
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }

  .top-bar-scrolled {
    box-shadow: 0 6px 4px -4px rgba(0,0,0,.3);
  }

  h3#gridHeader{
    font-weight: normal !important;
    letter-spacing: 4px;
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;
  }
  .btn-red {
    color: #fafafa;
    background-color: #e80061;
    border-color: #cf0056;
    width: 100%;
    max-width: 380px;
    margin: 30px auto;
    display: block;
    font-weight: normal;
    font-size: 20px;
    letter-spacing: 2px;
    &:hover{
      color: #fafafa;
      background-color: #b5004c;
      border-color: #91003d;
    }
  }
  textarea#company_meta_comments::placeholder {
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 2px;
    color: #7d7d7d;
    padding-left: 35px;
    text-align: center;
    padding-top:0;
  }
  fieldset.captchaField{
    display: inline-block;
    float: right;
  }
  fieldset.addUser{
    display: inline-block;
    float: left;
  }
  fieldset.registrer {
    display: block;
    clear: both;
  }
}
.modal{
  color: #3d3d3d;
}
p.lead-condensed a{
  color: #ff0f6c;
}
.uefa-hive, #hive_login-page{

  color: $uefa_hive;
  height: 100%;

  h1.signinText{
    color: $uefa_hive;
  }
  #forgotPassword .btn-red {
    background-color: $uefa_hive;
    border-color: $uefa_hive;
    color: white;
  }
  .passwordlink a {
    color: $uefa_hive;
  }
  .signIn{
    margin-top: 100px;
  }
  #forgotPassword .btn-red:hover {
    background-color: $uefa_hive;
    border-color: $uefa_hive;
  }
  #forgotPassword .lead-condensed{
    color: $uefa_hive;

  }
  .loggedInn h2, .forgotPwd h2, .changedPwd h2{
    color: $uefa_hive;
  }
  .logoLogIn{
    background-image: none;
  }
  h1.signinText {
    font-size: 28px;
    font-weight: normal;
    p{
      font-size: 36px;
      margin-bottom: 20px;
    }
  }
  .forgotPwd h1, .forgotPwd h2, .changedPwd h1, .changedPwd h2{
    font-weight: normal !important;
  }
  img.login-logo-top{
    display: none !important;
  }
}
@import './mixins';
@import '../components/noticebar/css/noticebar';
$uefa_hive: #063676;
$active-bg-color: #ff0f6c;
body {
  background-color: initial;
}
body.login-pg.uefa-hive, #forgotpwd-page body.uefa-hive, html#changepwd-page body.uefa-hive{

  background-image: url(/images/hive-background.png) !important;

}

.responsive-wrap {
  @media screen {
    white-space: nowrap;
  }
  @media screen and (max-width: 480px) {
    white-space: initial;
  }
}

#mediabankIn30.sliderTextHeading {
  margin-top: 100px;
  @media screen and (max-width: 480px) {
    font-size: 50px;
    line-height: 50px;
    margin-top: 40px;
  }
}

.mobile-align-left {
  margin-left: 40px;
  @media screen and (max-width: 480px) {
    margin-left: 0;
  }
}

.highlight-article {
  background-color: #6DC69D;
  color: #ffffff;
  font-weight: normal;
  font-size: 34px;
  border-radius: 0;
  @media screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.extra-top-space {
  margin-top: 100px;
  @media screen and (max-width: 480px) {
    margin-top: 20px;
  }
}

#forgotPassword {
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;

  #container {
    background-color: #f2f0f0;
    -webkit-font-smoothing: antialiased;
  }

  .btn-red {
    background-color: $active-bg-color;
    border-color: #ff0051;
    width: 100%;
    margin-top: 0px;
    margin-right: -10px;
    color: white;
    @include breakpoint(xsmall) {
      width: 130px;
    }
    &:hover {
      color: #fafafa;
      background-color: #b5004c;
      border-color: #91003d;
    }
  }
  .well-rounded {
    font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
    width: 500px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 35px;
    border-radius: 4px;
    border: 1px solid #c8c8c8;
  }

  .h2-mb {
    font-weight: normal;
  }

  .lead-condensed {
    color: white;
    font-size: 16px;
    font-weight: normal;
  }
}

#top-menu {
  .dropdown-menu {
    width: 150px;
    min-width: 140px;
    padding: 0;
  }
  #accountDropdown {
    background-color: #3d3d3d;
    width: 150px;
    min-width: 140px;
    padding: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(xsmall) {
      width: 130px;
      min-width: 130px;
    }
    .input-group {
      padding-left: 10px;
    }
  }
  #loginDropdown {
    width: 150px;
    min-width: 140px;
    padding: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(xsmall) {
      width: 130px;
      min-width: 130px;
    }
  }
  .input-group {
    .dropdown-text {
      padding: 5px 0;
      &.password {
        border-top: 1px dotted white;
      }
      a {
        color: white;
        text-decoration: none;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
  .password-out, .support-link {
    a {
      color: black;
      text-decoration: none;
      cursor: pointer;
      font-size: 12px;
      margin: 0 auto;
      display: block;
      width: 150px;
    }
  }
  a.account-link::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #ffffff transparent transparent transparent;
    content: "";
    display: inline-block;
    padding-top: 5px;
    margin-left: 7px;
  }
  &.well {
    @include breakpoint(xlarge) {
      padding-right: 0;
    }
    @include breakpoint(xsmall) {
      padding-left: 0px;
      padding-right: 8px;
    }
  }
  .text-center.col-sm-2 {
    padding-right: 0;
  }
  @include breakpoint(xlarge) {
    #accountDropdown {
      margin-left: 13px;
    }
    #loginDropdown {
      margin-left: 20px;
    }
  }
  @include breakpoint(mediumless) {
    .mblogo {
      margin-bottom: 20px;
    }
  }
  .signin {
    background-color: $active-bg-color;
    width: 150px;
    margin-top: 0px;
    margin-right: -10px;
    @include breakpoint(xsmall) {
      width: 130px;
    }
  }
  a.account-link {
    background-color: #3d3d3d;
    width: 150px;
    margin-top: 0;
    @include breakpoint(xsmall) {
      width: 130px;
    }
  }
  button.btn.sign-btn {
    text-transform: inherit;
    color: white;
    background-color: #3d3d3d;
    font-weight: normal;
    width: 150px;
    margin-top: 1px;
  }
}
.alert-danger {
  color: red;
  background-color: white;
  border: 1px solid #ccc;
  font-size: 16px;
  text-align: center;
  p{
    color: red;
  }
}
.neplogo{
  display: block;
  margin: 0 auto;
}
.logoLogIn {
  background-image: url(/images/Invert-MB_pink_tall_makes-life-easier.png);
  width: 236px;
  height: 144px;
  background-repeat: no-repeat;
  background-size: 236px 144px;
  overflow: hidden;
  position: relative;
  background-position: center;
  margin: 40px auto;
}
.loggedInn, .forgotPwd, .changedPwd {
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
  h2{
    color: white;
    text-align: center;
  }
  button{
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 2px;
    height: 48px;
    @include breakpoint(xsmall){
      width: 280px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  select{
    height: 48px;
    @include breakpoint(xsmall){
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.textLogged {
  font-size: 30px;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}
.loggedInName{
  font-size: 24px;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}
.btn-continueWork{
  background-color: rgb(232, 0, 97);
  padding: 6px 10px;
  border-width: 0;
  border-radius: 6px;
  border-style: solid;
  background-size: auto;
  background-repeat: repeat;
  background-position: center center;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  height: 45px;
  color: white;
  margin: 0 auto 20px;
  display: block;
  &:hover{
    color: white;
  }
}

body.login-pg, #forgotpwd-page body, html#changepwd-page {
  background-image: url(/images/The-Factory-process.jpg) ;
  background-repeat: no-repeat;
  height: 100%;
  background-size: 100%;
  background-color: #000002;
}

textarea#company_meta_comments::placeholder, input.form-control::placeholder{
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 2px;
  text-align: center;
  padding-top: 0;
}

.btnLogout {
  border-color: rgb(221, 221, 221);
  background-color: rgb(61, 61, 61);
  background-image: none;
  color: rgb(255, 255, 255);
  padding: 6px 10px;
  border-width: 0;
  border-radius: 6px;
  border-style: solid;
  background-size: auto;
  background-repeat: repeat;
  background-position: center center;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  height: 45px;
  &:hover{
    color: white;
  }
}

select#changeRepresentCompanySelect{
  margin-bottom: 20px;
  width: 100%;
  color: rgb(102, 102, 102);
  font-size: 17px;
  font-weight: normal;
  @include breakpoint(xsmall){
    width: 280px;
  }
}
.signIn {
  margin: 0 auto;
  display: block;
  float: none;
  color: white;
  width: 100%;
  max-width: 380px;
  input{
    border-radius: 0;
    max-width: 380px;
    height: 48px;
  }
}
h1.signinText{
  font-size: 36px;
  letter-spacing: 4px;
  font-weight: normal;
}
label.labelpassword, label.labelusername{
  display: none;
}
.forgotPwd, .changedPwd{
  h1, h2{
    font-weight: normal !important;
    letter-spacing: 4px;
    font-size: 36px;
  }
}
.passwordlink {
  a {
    color: #fafafa;
  }
}
.form-group {
  color: #fafafa;
  input.input-lg, input.form-control {
    font-weight: normal;
    font-size: 17px;
    letter-spacing: 2px;
    color: #3d3d3d;
    text-align: center;
    height: 44px;
    border-radius: 3px;
    @include breakpoint(xsmall){
      font-size: 15px;
      max-width: 280px;
      margin:0 auto;
      display: block;
    }
  }
}
select.form-control{
  height: 44px;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 2px;
}
@include breakpoint(xsmall) {
  input#username, input#password{
    max-width: 280px;
    margin: 0 auto;
  }
  p.lead-condensed {
    max-width: 280px;
    text-align: center;
  }
  .passwordlink{
    max-width: 280px;
    text-align: center;
    margin-left: 10px;
  }
}
.errorAppSwitch{
  color: #fafafa;
}
#forgotpwd-page{
  .ui-pnotify-history-container{
    display: none;
  }
  .alert{
    display: none;
  }
}
.noaccount{
  text-align: center;
  font-size: 28px;
  font-weight: normal;
  color: #fafafa;
  margin-bottom: 20px;
}
.tryagain{
  height: 48px;
  color: #fafafa;
  background-color: #e80061;
  border-color: #cf0056;
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
  display: block;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 2px;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
}
.forgotPwdError {
  a{
    text-decoration: none;
    p:hover {
      background-color: #b5004c;
      border-color: #91003d;
    }
  }
}
#customer_registration-page, #forgotpwd-page{

  body{
    background-color: #000105;
    background-image: url(/images/The-Factory-process.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    color: white;
    letter-spacing: 1px;
  }
  .errorBorder {
    border-color: #FFAAAA;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .passOK {
    color: #009900;
  }

  .passNOTOK {
    color: #990000;
  }

  .form-inline {
    display: inline-block !important;
  }

  .divider {
    height: 1px;
    background: #999;
    margin: 5px 0px 15px 0px;
  }

  #submitFormButton.disabled {
    background-color: #e3e3e3;
    border-color: #b5b5b5;
  }
  fieldset {
    margin-bottom: 20px;
  }

  .form-group {
    .description {
      display: block;
      margin-top: 5px;
      margin-bottom: 10px;
      font-size: 12px;
      padding-left: 5px;
    }

    .checkbox {
      font-size: 14px;
    }

    .description.passNOTOK {
      color: #e51800 !important;
    }

    .description.passOK {
      color: #5cb85c !important;
    }
    p {
      font-size: 1.7rem;
      font-weight: normal;
      color: #fafafa;
      margin: 0 auto 20px;
    }
  }
  .form-title {
    color: #6f6f6f;
  }

  .user {
    padding-right: 3px;
  }

  legend {
    font-size: 16px;
    margin-bottom: 5px;
    color: white;
    border-bottom: 0;
  }
  label, legend {
    font-weight: normal !important;
  }

  .center-panel{
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }

  .top-bar-scrolled {
    box-shadow: 0 6px 4px -4px rgba(0,0,0,.3);
  }

  h3#gridHeader{
    font-weight: normal !important;
    letter-spacing: 4px;
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;
  }
  .btn-red {
    color: #fafafa;
    background-color: #e80061;
    border-color: #cf0056;
    width: 100%;
    max-width: 380px;
    margin: 30px auto;
    display: block;
    font-weight: normal;
    font-size: 20px;
    letter-spacing: 2px;
  }
  textarea#company_meta_comments::placeholder {
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 2px;
    color: #7d7d7d;
    padding-left: 35px;
    text-align: center;
    padding-top:0;
  }
  fieldset.captchaField{
    display: inline-block;
    float: right;
  }
  fieldset.addUser{
    display: inline-block;
    float: left;
  }
  fieldset.registrer {
    display: block;
    clear: both;
  }
}
.modal{
  color: #3d3d3d;
}
.partnerlink{
  a {
    display: block;
    color: white;
    font-size: 18px;
    margin: 0 auto;
    margin-top: 20px;
    text-decoration: none;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}

//hive:
.uefa-hive, #hive_login-page{

  color: $uefa_hive;
  height: 100%;

  h1.signinText{
    color: $uefa_hive;
  }
  #forgotPassword .btn-red {
    background-color: $uefa_hive;
    border-color: $uefa_hive;
    color: white;
  }
  .passwordlink a {
    color: $uefa_hive;
  }
  .signIn{
    margin-top: 100px;
  }
  #forgotPassword .btn-red:hover {
    background-color: $uefa_hive;
    border-color: $uefa_hive;
  }
  #forgotPassword .lead-condensed{
    color: $uefa_hive;

  }
  .loggedInn h2, .forgotPwd h2, .changedPwd h2{
    color: $uefa_hive;
  }
  .logoLogIn{
    background-image: none;
  }
  h1.signinText {
    font-size: 28px;
    font-weight: normal;
    p{
      font-size: 36px;
      margin-bottom: 20px;
    }
  }
  .forgotPwd h1, .forgotPwd h2, .changedPwd h1, .changedPwd h2{
    font-weight: normal !important;
  }
  img.login-logo-top{
    display: none !important;
  }
}
#hive_site {
  .btn-continueWork {
    background-color: #063676;
    border-color: #063676;
    color: white;
  }
  .textLogged{
    color: #063676;
  }
  .loggedInName {
    color: #063676;
  }
}

.hive_body {
  background-image: url('/images/hive-background.png') !important;
  .logoLogIn {
    visibility: hidden;
  }
  .btn-red {
    background-color: #063676 !important;
    border-color: #063676 !important;
    color: white !important;
  }
  .h2-mb {
    color: #063676 !important;

  }
  .lead-condensed {
    color: #063676 !important;
  }
  .noaccount {
    p {
      color: #063676 !important;
    }
  }
  .tryagain {
    background-color: #063676 !important;
    border-color: #063676 !important;
    color: white !important;
  }
}

.logoLogInHive {
  background-image: url(/icons/hive_original.png);
  background-size: 150px 150px;
  display: block;
  width: 150px;
  height: 150px;
  margin: 0 auto;
}



