/**
 * Created by westerp on 10.12.2015.
 */
$app_color: #b8d545 !default;

body .noticebar {
  position: fixed;
  background-color: $app_color;
  color: white;
  opacity: 0.95;
  padding: 8px 10px;
  top: 0;
  width: 100%;
  z-index: 11000;
  display: flex;
  margin: auto;

  &__content {
    font-size: 14px;
    margin: auto;
    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__button {
    margin: 0 10px;
  }

  section {
    margin-bottom: 10px;
  }
}
