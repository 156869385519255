// White Label UEFA theme
//Colors are brought from _themes-color-palette
// to do: move the selectors here from the labeled.scss

body.white-label, body.company-344001.ThemeDefault {
  --backgroundImage: var(--labeledBackgroundImage);
  --logoLesUefa: url("../../images/logo-LES-lighttheme.png");

  &.library_app, &.review_app, &.compliance_app {
    --pagerSelected: var(--blueLightHive);
    --appColor: var(--blueDarkTheme);
    --appHighlight: var(--blueDarkTheme);
  }

  --pagerSelected: var(--blueLightHive);
  --activatedAnnotationActionColor: var(--activatedAnnotationActionColorHive);
}