// *** THEMES ***
//default, light theme. Colors are brought from _themes-color-palette

.ThemeDefault, body {
  --themecolor_bgr: var(--offwhite);
  --panelBackground: var(--white);
  --panelBorder: var(--veryLightGray);
  --ink: var(--darkInk);
  --contraInk: var(--lightInk);
  --tabInk: var(--tabInkLight);
  --separator: var(--hrThemeLight);
  --mainMenuLine: var(--darkOverlay);
  --buttonBackground: var(--white);
  --menu-background: var(--white);
  --button-hover: var(--lightGray);
  --aria-selected: var(--lightGray);
  --themecolor-form: var(--offwhite);
  --thumbFooter: var(--thumbDarkFooter);
  --hrTheme: var(--hrThemeLight);
  --btnBorder: var(--lightThemeBorder);
  --thirdLevel: var(--thirdLevelLight);
  --borderThumb: var(--borderThumbLight);
  --hoverButtonBorder: var(--lightThemeBorder);
  --hoverButton: var(--offwhite);
  --hoverBackground: var(--veryLightGray);
  --brightness: var(--notSoBright);
  --invBrightness: var(--veryBright);
  --tableBackground: var(--white);
  --tableSeparator: var(--veryLightGray);
  --tableAltBackground: var(--transparent);
  --inkSelected: var(--black);
  --inkContrast: var(--coalDark);
  --grayScale: var(--dontGray);
  --tabBackground: var(--whiteTab);
  --tabBackground-active: var(--whiteTab);
  --modalBackground: var(--white);
  --popover: var(--popoverInk);
  --disabledButton: var(--disabledButtonLight);
  --disabledInk: var(--disabledInkLight);
  --disabledIcon: var(--lightGray);
  --inputField: var(--inputFieldLight);
  --inputFieldBorder: var(--inputFieldBorderLight);
  --hoverInk: var(--hoverInkLight);
  --darkButton: var(--darkButtonLight);
  --adexBorder: var(--veryLightGray);
  --markedField: var(--markedFieldLight);
  --shadedButton: var(--shadedButtonLight);
  --datePicker: var(--datePickerLight);
  --mBox: var(--mboxLight);
  --tableStripe: var(--tableStripeLight);
  --backgroundImage: var(--noneBackgroundImage);
  --displayIcons: var(--displayIconsYes);
  --displayIconsDark: var(--displayIconsNo);
  --displayIconsSmallDark: var(--displayIconsNo);
  --mboxOpen: var(--mboxOpenWhite);
  --mboxClosed: var(--mboxClosedWhite);
  --buttonGroupBorder: var(--lightThemeBorder);
  --audiotracks: var(--audiotracksLight);
  --disabledField: var(--disabledFieldLight);
  --bilboardButtonsCalendar: var(--bilboardButtonsCalendarDark);
  --bilboardButtonsCalendarActive: var(--bilboardButtonsCalendarLight);
  --bilboardButtonsCollection: var(--bilboardButtonsCollectionDark);
  --bilboardButtonsCollectionActive: var(--bilboardButtonsCollectionLight);
  --inkCalendar: var(--darkInk);
  --calendarBackground: var(--calendarBackgroundLight);
  --discreteBorder: var(--discreteBorderLight);
  --bodyParts: var(--bodyPartsLight);
  --substBackground: var(--substBackgroundLight);
  --detailViewBackground: var(--veryVeryLightGray);
  --evenRow: var(--evenRowLight);
  --tableHelpStripe: var(--tableThBorderLight);
  --hrThemeWeak: var(--hrThemeWeakLight);
  --readonlyInk: var(--readOnlyLight);
  --strongInk: var(--strongInkDark);
  --mediabankIcon: var(--mediabankIconLight);
  --activatedAnnotationActionColor: var(--activatedAnnotationActionColorLight);
  --backgroundEventAnnotationLeft: var(--backgroundEventAnnotationLeftLight);
  --darkCircle: var(--darkCircleLight);
  --eventButtonText: var(--eventButtonTextLight);
  --selectedFieldReview: var(--selectedFieldReviewLight);
  --btnSeparator: var(--lightThemeBorder);

  &.library_app {
    --appColor: var(--libraryColor);
    --pagerSelected: var(--selectedFieldLibraryLight);
    --selectedAppColor: var(--selectedFieldLibraryLight);
    --appHighlight: var(--libraryColor);
  }

  .adex {
    --appColor: var(--adexColor);
  }

  &.review_app {
    --appColor: var(--rnaColor);
    --pagerSelected: var(--selectedFieldReviewLight);
  }

  &.admin, .admin {
    --appColor: var(--adminColor);
  }

  &.dashborad_app {
    --appColor: var(--dashboardColor);
  }

  &.compliance_app {
    --appColor: var(--complianceColor);
    --pagerSelected: var(--selectedFieldComplianceLight);
  }
  &.liveevent_supervision_app {
    --pagerSelected: var(--appLesLight);
  }
}