// Dark Theme color definitions. Colors are brought from _themes-color-palette

body.ThemeDark {
  --themecolor_bgr: var(--darkestGround);
  --panelBackground: var(--darkOverlay);
  --panelBorder: var(--darkOverlay);
  --ink: var(--lightInk);
  --contraInk: var(--darkInk);
  --tabInk: var(--ink);
  --separator: var(--darkSeparator);
  --mainMenuLine: var(--darkestGround);
  --buttonBackground: var(--buttonDark);
  --menu-background: var(--darkOverlay);
  --button-hover: var(--buttonDark);
  --aria-selected: var(--lightGray);
  --themecolor-form: var(--darkestGround);
  --thumbFooter: var(--thumbDarkFooter);
  --hrTheme: var(--hrThemeDark);
  --btnBorder: var(--buttonDark);
  --thirdLevel: var(--thirdLevelDark);
  --borderThumb: var(--borderThumbDark);
  --hoverButtonBorder: var(--darkThemeBorder);
  --hoverButton: var(--buttonDark);
  --hoverBackground: var(--darkestGround);
  --brightness: var(--veryBright);
  --invBrightness: var(--notSoBright);
  --tableBackground: var(--thirdLevelDark);
  --tableSeparator: var(--lightGray);
  --tableAltBackground: var(--tableBackground);
  --inkSelected: var(--white);
  --inkContrast: var(--black);
  --grayScale: var(--doGray);
  --tabBackground: var(--darkOverlay);
  --tabBackground-active: var(--darkOverlay);
  --modalBackground: var(--modalDarkBackground);
  --popover: var(--popoverInk);
  --disabledButton: var(--disabledButtonDark);
  --disabledInk: var(--disabledInkDark);
  --disabledIcon: var(--disabledInk);
  --inputField: var(--inputFieldDark);
  --inputFieldBorder: var(--inputFieldBorderDark);
  --hoverInk: var(--hoverInkDark);
  --darkButton: var(--darkButtonDark);
  --adexBorder: var(--adexBorderDark);
  --markedField: var(--markedFieldDark);
  --shadedButton: var(--shadedButtonDark);
  --datePicker: var(--datePickerDark);
  --mBox: var(--mboxDark);
  --tableStripe: var(--tableStripeDark);
  --backgroundImage: var(--noneBackgroundImage);
  --displayIcons: var(--displayIconsYes);
  --displayIconsDark: var(--displayIconsNo);
  --displayIconsSmallDark: var(--displayIconsNo);
  --mboxOpen: var(--mboxOpenWhite);
  --mboxClosed: var(--mboxClosedWhite);
  --buttonGroupBorder: var(--darkThemeBorder);
  --audiotracks: var(--audiotracksDark);
  --disabledField: var(--disabledFieldDark);
  --bilboardButtonsCalendar: var(--bilboardButtonsCalendarLight);
  --bilboardButtonsCalendarActive: var(--bilboardButtonsCalendarDark);
  --bilboardButtonsCollection: var(--bilboardButtonsCollectionLight);
  --bilboardButtonsCollectionActive: var(--bilboardButtonsCollectionDark);
  --inkCalendar: var(--white);
  --calendarBackground: var(--calendarBackgroundDark);
  --discreteBorder: var(--discreteBorderDark);
  --bodyParts: var(--bodyPartsDark);
  --substBackground: var(--substBackgroundDark);
  --detailViewBackground: var(--darkOverlay);
  --evenRow: var(--evenRowDark);
  --tableHelpStripe: var(--tableThBorderDark);
  --hrThemeWeak: var(--hrThemeWeakDark);
  --readonlyInk: var(--readOnlyDark);
  --strongInk: var(--strongInkLight);
  --mediabankIcon: var(--mediabankIconDark);
  --activatedAnnotationActionColor: var(--activatedAnnotationActionColorDark);
  --backgroundEventAnnotationLeft: var(--backgroundEventAnnotationLeftDark);
  --darkCircle: var(--darkCircleDark);
  --eventButtonText: var(--eventButtonTextDark);
  --selectedFieldReview: var(--selectedFieldReviewDark);
  --btnSeparator: var(--discreteBorderDark);


  &.library_app {
    --appColor: var(--libraryColor);
    --pagerSelected: var(--selectedDark);
    --selectedAppColor: var(--selectedFieldLibraryLight);
    --appHighlight: var(--libraryColor);
  }

  .adex {
    --appColor: var(--adexColor);
  }

  &.review_app {
    --appColor: var(--rnaColor);
    --pagerSelected: var(--selectedDark);
  }

  .admin {
    --appColor: var(--adminColor);
  }

  &.dashborad_app {
    --appColor: var(--dashboardColor);
  }

  &.compliance_app {
    --appColor: var(--complianceColor);
    --pagerSelected: var(--selectedDark);
  }
  &.liveevent_supervision_app {
    --pagerSelected: var(--appLesDark);
  }
}

//only Dark Theme:
.ThemeDark {
  .inline-tabs__tab-text:after {
    background: none;
  }

  .inline-tabs__tab {
    &.active,
    &:hover {
      .inline-tabs__tab-text:after {
        background: none;
      }
    }
  }

  .dropdownMainMenu, .adex .dropdownMainMenu {
    border-top-color: var(--darkestGround);
    border-top: 2px solid var(--darkestGround);
  }

  .mnw-modal .modal-header {
    background-color: var(--buttonBackground);
    background: var(--buttonBackground);
    color: var(--ink);
  }
  ::-webkit-scrollbar{
    background-color:  var(--scrollBackgroundDark);
  }
  ::-webkit-scrollbar-track{
    background-color:  var(--scrollBackgroundDark);
  }
  ::-webkit-scrollbar-thumb{
    background-color: var(--coalDark);
    border-radius: 10px;
  }
  ::-webkit-resizer{
    background-color: var(--coalDark);
  }
}