// White Label Euro2020 ******************************************* 2020
//this file contains the whole styling for Euro2020 theme, both layout,colors and color variables
//Incl. the Euro2020 login pages
//Colors are brought from _themes-color-palette

body.white-label.company-1176004.ThemeDefault, body.white-label.company-1194004.ThemeDefault, body.white-label.company-1208004.ThemeDefault, body.white-label.shade_euro2020, body.shade_euro2020 {
  --themecolor_bgr: var(--euroGray);
  --panelBackground: var(--offwhite);
  --panelBorder: 0;
  --ink: var(--darkInk);
  --contraInk: var(--lightInk);
  --separator: var(--hrThemeLight);
  --mainMenuLine: var(--darkOverlay);
  --buttonBackground: var(--white);
  --menu-background: var(--whiteTransparent);
  --button-hover: var(--lightGray);
  --aria-selected: var(--lightGray);
  --themecolor-form: var(--offwhite);
  --thumbFooter: var(--thumbDarkFooter);
  --hrTheme: var(--hrThemeLight);
  --btnBorder: var(--euroGray);
  --thirdLevel: var(--thirdLevelLight);
  --borderThumb: var(--borderThumbLight);
  --hoverButtonBorder: var(--lightThemeBorder);
  --hoverButton: var(--offwhite);
  --brightness: var(--notSoBright);
  --invBrightness: var(--veryBright);
  --tableBackground: var(--white);
  --tableSeparator: var(--veryLightGray);
  --tableAltBackground: var(--transparent);
  --inkSelected: var(--black);
  --grayScale: var(--dontGray);
  --tabBackground: var(--euroTab);
  --tabBackground-active: var(--euroTabActive);
  --modalBackground: var(--white);
  --popover: var(--popoverInk);
  --disabledButton: var(--disabledButtonLight);
  --disabledInk: var(--disabledInkLight);
  --inputField: var(--inputFieldLight);
  --inputFieldBorder: var(--inputFieldBorderLight);
  --hoverInk: var(--hoverInkLight);
  --darkButton: var(--euro2020Theme);
  --adexBorder: var(--veryLightGray);
  --markedField: var(--markedFieldLight);
  --shadedButton: var(--shadedButtonLight);
  --datePicker: var(--datePickerLight);
  --mBox: var(--mboxLight);
  --tableStripe: var(--tableStripeLight);
  --backgroundImage: var(--noneBackgroundImage);
  --displayIcons: var(--displayIconsYes);
  --displayIconsDark: var(--displayIconsNo);
  --displayIconsSmallDark: var(--displayIconsNo);
  --mboxOpen: var(--mboxOpenWhite);
  --mboxClosed: var(--mboxClosedWhite);
  --buttonGroupBorder: var(--lightThemeBorder);
  --audiotracks: var(--audiotracksLight);
  --disabledField: var(--disabledFieldLight);
  --bilboardButtonsCalendar: var(--bilboardButtonsCalendarDark);
  --bilboardButtonsCalendarActive: var(--bilboardButtonsCalendarLight);
  --bilboardButtonsCollection: var(--bilboardButtonsCollectionDark);
  --bilboardButtonsCollectionActive: var(--bilboardButtonsCollectionLight);
  --inkCalendar: var(--darkInk);
  --calendarBackground: var(--calendarBackgroundLight);
  --discreteBorder: var(--discreteBorderLight);
  --bodyParts: var(--bodyPartsLight);
  --substBackground: var(--substBackgroundLight);
  --evenRow: var(--evenRowLight);
  --activatedAnnotationActionColor: var(--blueDarkTheme);
  --backgroundEventAnnotationLeft: var(--backgroundEventAnnotationLeftLight);
  --eventButtonText: var(--eventButtonTextLight);
  --selectedFieldReview: var(--selectedFieldReviewLight);

  .library, .review, .compliance {
    --pagerSelected: var(--blueLightHive);
    --appColor: var(--blueDarkTheme);
  }

  --pagerSelected: var(--blueLightHive);

  // Euro2020 trunk (contains certan layout properties, not only colors):

  #header {
    // background-color: var(--blueTransparent);
    background-image: url(/images/header-euro2020.png);
  }

  .btn-dark {
    background-color: var(--euro2020Theme);
    border-radius: 0;
    border-color: var(--euroGray);
  }

  &.dashborad_app {
    .panel {
      border-bottom: 1px dashed #969494;
    }
  }

  .ui-component__pullup-toggler {
    background-color: var(--euro2020Theme);

    .fa, .fa-info-circle {
      color: white;
    }
  }

  .context-menu-item.context-menu-hover:not(.context-menu-separator) {
    background-color: var(--euro2020Theme) !important;
  }

  .panel {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
  }

  .panel-heading {
    &:before {
      color: var(--euro2020Theme);
    }
  }

  #datetime-component.datetime-component b.date {
    color: var(--euro2020Theme);
  }

  #welcomemessage-component .role, .company-344001 #welcomemessage-component .role {
    color: var(--euro2020Theme);
  }

  .workspacespan {
    float: left !important;
    margin-left: 30px;
    margin-top: -8px;

    .workspace-switcher {
      display: none !important;
    }

    .mainmenuToggler {
      background-color: transparent;
      border: 0;
      color: white;
    }

    .homebutton {
      border: 0;
      background: transparent;
      color: white;
      border-right: 1px solid white;
    }
  }

  .uefa-hive-name {
    color: transparent !important;

    &:after {
      display: inline-block;
      position: absolute;
      content: url(/images/logo-euro2020.png);
      top: -10px;
      left: 0;
      right: 0;
    }

    &:before {
      display: inline-block;
      position: absolute;
      content: url(/images/hive_remote.png);
      float: right;
      right: 0;
      margin-right: 20px;
      top: 10px;
    }
  }

  .modal-content {
    background-color: white;
  }

  .assetinfomodal__field-group-title .inner {
    background-color: white;
  }

  .library #searchgrid .k-state-selected, .library #searchlist .k-state-selected, .library #searchworklist .k-state-selected, table .k-state-selected {
    background-color: var(--euro2020Theme) !important;
    color: white;
    border-color: var(--euro2020Theme);
  }

  &.workspace-w3, &.workspace-w4, &.workspace-w5 {
    .panel {
      background-color: var(--offwhite);
    }
  }

  .context-menu-list {
    background-color: var(--offwhite);
  }

  .context-menu-item:not(.context-menu-separator):hover {
    background-color: var(--euro2020Theme) !important;
    color: white;

    > span .attachment-download__title {
      color: white;
    }
  }

  li.context-menu-item.attachment-download:hover {
    > span .attachment-delete a i.fa.fa-trash {
      color: white;
    }
  }

  .collection {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .collection__label {
    max-width: 95%;
    position: absolute;
    font-size: 1.4vw;
    border-bottom: 0;
    color: white;
  }

  &.workspace-w3, &.workspace-w4 {
    .calendar__day, .calendar__weekday {
      border: 1px solid var(--discreteBorder);
    }
  }

  &.workspace-w1, &.workspace-w2, &.workspace-w5 {
    .calendar__day, .calendar__weekday {
      border: 1px solid white;
      --calendarBackground: var(--calendarBackgroundWhite);
    }
  }

  .js-share-tab, .share-tab {
    display: none !important;
  }

  .k-block, .k-widget {
    background-color: white !important;
  }

  .pager-container {
    .k-block, .k-widget {
      background-color: transparent !important;
    }
  }

  &.workspace-w5, &.workspace-w3, &.workspace-w4 {
    @media (min-width: 1250px) {
      #playermodule.playermodule #mediabankPlayerControlContainer {
        width: 72%;
        margin: 0 auto;
      }
    }
  }

  .mboxpullup__mboxes {
    [data-id='quicklink'] {
      display: none;
    }
  }

  .k-state-hover {
    color: white !important;
    background-color: var(--euro2020Theme);

    a {
      color: white;
    }
  }

  li.callus {
    display: none;
  }

  //calendar date hack
  @media(min-width: 1000px) {
    .calendar__date__left {
      position: absolute;
      text-align: center;
      top: 20px;
      left: 0;
      right: 0;
      margin: auto;
      width: 155px;
    }
    .calendar__date__right {
      margin: 0 auto;
      display: block;
      flex: initial;
    }
    .calendar__kendo-cal {
      margin-top: 5px;
      margin-left: 10px;
    }
  }

  .calendar__label__value.today {
    color: white;
  }

  .mobileCompanyName {
    display: none;
  }

  @media (max-width: 899px) {
    .navbar-header .header-navigation {
      margin: 20px 0 0 0;
      display: block;
      position: absolute;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
    }
  }

  @media (max-width: 957px) {
    .header-navigation .mainmenyHomeGroup {
      margin: 0;
      width: initial;
      float: left;
    }
  }
  @media (min-width: 650px) {
    .navbar-header .header-navigation {
      margin-top: 18px;
      width: 100%;
    }
  }
  @media (max-width: 899px) {
    .dropdownMainMenu {
      top: 82px;
    }
  }
  @media (max-width: 649px) {

    .header-navigation {
      width: 100%;
    }
    .uefa-hive-name {
      width: 100%;
    }
    .uefa-hive-name:before {
      display: none;
    }
    .uefa-hive-name:after {
      left: initial;
      right: 10px;
    }
    .workspacespan {
      margin-top: -18px;
    }
  }


  // disappearing of the qucklinks:
  li.context-menu-item.quicklink-title.css-title.context-menu-submenu {
    display: none !important;
  }

  #header-menu-addtoquicklink, #header-menu-sendquicklink, #header-menu-groupassets {
    display: none;
  }

  .js-actions-menu { //removes the divider under the QL
    ul.header-menu {
      li.header-menu__item.divider:nth-child(3) {
        display: none;
      }
    }
  }

  .js-tasks-menu {
    ul.header-menu {
      li.header-menu__item:nth-child(1), li.header-menu__item:nth-child(2) {
        display: none;
      }
    }
  }

  .groupscontextual, .projectcontextual, .removeFromProject {
    display: none !important;

    + .context-menu-separator {
      display: none !important;
    }
  }

  #cliplist .k-state-focused, #cliplist .k-state-selected {
    color: white;
    background-color: var(--euro2020Theme);
  }

  .calendar__weekday {
    background-color: var(--euro2020Theme);
    color: white;
    font-family: BridgeType-Regular;
  }

  .js-jobmonitor-link {
    display: block;
  }

  a.btn.btn-default.workspaceicon {
    display: block;
    color: transparent;
    background-color: transparent;
    border: 0;
    border-left: 1px solid white;
    border-radius: 0;
    height: 22px;

    &:before {
      content: ' ';
      background-image: url('/images/clipboard.png');
      background-size: 12px 16px;
      background-repeat: no-repeat;
      display: block;
      height: 16px;
      width: 12px;
      margin-top: 2px;
    }
  }

  .workspacespan {
    .fa-home:before {
      font-size: 15px;
    }
  }

  .fa.fa-bars.mainmenuToggler:before {
    cursor: pointer;
    position: relative;
  }

  .mboxpullup__scroll-wrap.js-pullup-container {
    box-shadow: 1px 1px 2px #2d2d2d;
  }

  .wrapper-logo-login {
    display: none;
  }

  .jobmonitor .btn-group {
    span.btn.btn-default.workspaceicon {
      display: none;
    }
  }

  #collections {
    .collection__label {
      display: none;
    }
  }

  .mnw-form__fieldset-title {
    background-color: var(--offwhite);
  }

  //hide search in W5:
  &.workspace-w5 {
    #billboard .breadcrumb-view {
      padding: 0 10px 0 5px;
    }

    .panel {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }

    #center-region {
      .assetgroups {
        background-color: #fafafa;
      }
    }

    #playermodule.playermodule {
      width: 100%;
      background-color: #fafafa;
      margin-top: -10px;
    }

    div#assettitle-component {
      padding-top: 0;

      .iterator {
        border-top: 0;
        padding-top: 0;
      }
    }

    #mainContent {
      margin-top: 0 !important;
    }

    #billboard {
      text-align: center;

      .billboard__breadcrumb {
        left: 0;
      }
    }
  }

  .active-row, .cliplist-overview__row.active-row {
    color: white;
    background-color: var(--euro2020Theme);
  }

  /*
  &:not(.event-mode_active){
  &.workspace-w2 {
    #searchlist > div.js-kendo-grid.kendo-grid.k-grid.k-widget > table > colgroup > col:nth-child(n+3):nth-child(-n+4) {
      display: none;
    }

    #searchlist > div.js-kendo-grid.kendo-grid.k-grid.k-widget > table > thead > tr > th:nth-child(n+3):nth-child(-n+4) {
      display: none;
    }

    #searchlist > div.js-kendo-grid.kendo-grid.k-grid.k-widget > table > tbody > tr > td:nth-child(n+3):nth-child(-n+4) {
      display: none;
    }
  }
  .color-theme--section{
    display:none;
  }
} */

  &.event-mode_active { //removes the hamburger menu from "event list"
    &.workspace-w2 {
      #searchlist > div.js-kendo-grid.kendo-grid.k-grid.k-widget > table > colgroup > col:nth-child(2) {
        display: none;
      }

      #searchlist > div.js-kendo-grid.kendo-grid.k-grid.k-widget > table > thead > tr > th:nth-child(2) {
        display: none;
      }

      #searchlist > div.js-kendo-grid.kendo-grid.k-grid.k-widget > table > tbody > tr > td:nth-child(2) {
        display: none;
      }
    }

  }

  .actions_menu, .tasks_menu, .admintask_menu, .admin-menu, .tooltipView {
    display: none;
  }

  li.js-eventsearch-tab.ui-component__tab { //don't use js- in styling
    display: none !important;
  }

  &.workspace-w5 {
    .calendar__date {
      margin-top: 18px;
    }

    .billboard__collapse {
      display: none !important;
    }
  }

  li.termsButton {
    display: none;
  }

  .workspacespan {
    min-width: auto;
  }

  @media (max-width: 957px) {
    .library .header-navigation .mainmenyHomeGroup {
      position: relative;
    }
    .library .js-jobmonitor-link {
      position: static;
    }
    .library .header-navigation .mainmenyHomeGroup {
      position: relative;
      top: 0;
      left: 0;
    }
  }
  @media (max-width: 899px) and (min-width: 650px) {
    .workspacespan {
      margin-top: 8px;
    }
  }

  .workspace-mobile__button {
    display: none;
  }

  span.add-to-quicklinks {
    .action-menu {
      cursor: pointer;
      font-size: 20px;
      margin-top: 15px;

      span.fa-bars {
        display: none;
      }

      button.order-button {
        display: block;
      }
    }
  }

  .color-theme--section {
    display: none;
  }

  li.context-menu-item.download-title {
    display: none !important;
  }

  li.mboxes-title {
    > span {
      display: none;

      &:after {
        content: 'Download';
        float: left;
        display: block;
        height: 12px;
      }
    }
  }

  li.context-menu-item.mboxes-title.css-title.context-menu-submenu {
    &:before {
      content: 'Download';
      position: relative;
      border-left: 0;
    }

    &:after {
      position: absolute;
      top: 50%;
      right: .5em;
      z-index: 1;
      width: 0;
      height: 0;
      content: '';
      border-color: transparent transparent transparent #2f2f2f;
      border-style: solid;
      border-width: .25em 0 .25em .25em;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  li.context-menu-item.context-menu-icon {
    display: none !important;
  }

  li.context-menu-item.datafeed-title.context-menu-icon {
    display: block !important;
  }

  li.context-menu-item.context-menu-icon.copyassetlink {
    display: block !important;
  }

  li.context-menu-item.context-menu-icon.copyassetlink ~ li {
    display: block !important;
  }

  li.context-menu-item.mboxes-title ~ li.context-menu-item.context-menu-separator {
    display: none !important;
  }

  .mboxes-title.css-title.no-sub-elements {
    display: none !important;
  }

  .livestream-placeholder {
    background-color: rgba(18, 104, 168, 1);
    background-image: url("/images/euro_poster_background.png");
    background-size: contain;
    color: white;
  }

  #searchgrid, #searchlist, #searchworklist {
    .poster-container.livestream-poster {
      background-color: rgba(18, 104, 168, 1);
      background-image: url("/images/euro_poster_background.png");
      background-size: contain;
    }
  }

  .searchassets .grid-item .poster.scheduled {
    background-color: rgba(18, 104, 168, 1);
    background-image: url("/images/euro_poster_background.png");
    background-size: contain;
  }

  .poster-info .info-text__text {
    color: white;
  }

  &.library_app.workspace-w2 {
    .livestream-poster a.poster {
      img {
        filter: brightness(0) invert(100%);
      }
    }
  }

  &.library_app.workspace-w1 {
    .poster-info {
      .info-text__icon img {
        filter: brightness(0) invert(100%);
      }

      .info-text__icon {
        margin-top: -105px;
      }
    }

    .live, .vod {
      .live-scheduled {
        display: none;
      }

      .live-start {
        display: none;
      }

      .live-end {
        display: none;
      }
    }

    .scheduled {
      .live-end {
        display: none;
      }

      .live-scheduled {
        display: none;
      }
    }
  }

  &.library_app.workspace-w3, &.library_app.workspace-w5 {
    .livestream-placeholder__end {
      display: none;
    }

    .livestream-placeholder__starts-in {
      margin-bottom: -14px;
    }
  }

  &.library_app {
    .livestream-placeholder__clock img {
      filter: brightness(0) invert(100%);
    }
  }

  &.library_app.workspace-w3, &.library_app.workspace-w4, &.library_app.workspace-w5 {
    .days-countdown {
      display: none;
    }
  }
}

// *************************  end for EURO2020 styling - inner pages   *******************=======

//Login page for Euro2020

body.login-pg.euro2020, #forgotpwd-page body.euro2020, html#changepwd-page body.euro2020, #hive_login-page body.login-pg.euro2020, #forgotpwd-page body.euro2020, html#changepwd-page body.euro2020, html#resetpwd-page body.euro2020 {
  background-color: #dedbdb !important;
  background-image: none;

  .logoLogIn {
    background-image: none;
    display: none;
  }

  #container {
    .row {
      margin: 0;
    }

    width: 100%;
    max-width: 100%;
    height: 100%;
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;

    background: -moz-linear-gradient(left, #dedbdb 0%, #dedbdb 50%, #007e8b 50%, #007e8b 100%);
    background: -webkit-linear-gradient(left, #dedbdb 0%, #dedbdb 50%, #007e8b 50%, #007e8b 100%);
    background: linear-gradient(to right, #dedbdb 0%, #dedbdb 50%, #007e8b 50%, #007e8b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dedbdb', endColorstr='#007e8b', GradientType=0);
    background-image: url(/images/euro2020-login.png);
    background-repeat: no-repeat;
    background-position: 500px 0;
    background-size: cover;
    background-attachment: fixed;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 136px;
      height: 160px;
      background-image: url(/images/euro2020logo.png);
      background-size: 136px 160px;
      left: calc(50% + 182px);
      top: calc(50% - 58px);
      -ms-transform: translateY(calc(50% - 150px));
      transform: translateY(calc(50% - 150px));
    }

    &:before {
      content: 'Hive Remote';
      display: block;
      position: absolute;
      left: calc(50% + 119px);
      top: calc(50% + 94px);
      -ms-transform: translateY(calc(50% - 90px));
      transform: translateY(calc(50% - 90px));
      color: white;
      font-family: BridgeType-Regular;
      font-size: 50px;
    }

    .loggedInn, .signIn, .forgotPwd, .changedPwd {
      margin-left: 110px;
      margin-top: 0;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      position: absolute;
      max-width: 280px;
    }

    img.login-logo-top {
      display: none !important;
    }

    .btnLogout:hover {
      color: var(--euro2020Theme);
    }

  }

  .btn-continueWork, #forgotPassword .btn-red {
    background-color: var(--euro2020Theme);
    border-color: var(--euro2020Theme);

    &:hover {
      background-color: var(--euro2020Theme);
      border-color: var(--euro2020Theme);
    }
  }

  h1.signinText {
    margin-top: 0;
    display: none;
  }

  textarea#company_meta_comments::placeholder, input.form-control::placeholder {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0;
  }

  .form-group {
    margin-bottom: 12px;
  }

  .logoLogInHive {
    display: none;
  }

  .uefa-hive #forgotPassword .btn-red, #hive_login-page #forgotPassword .btn-red {
    background-color: var(--euro2020Theme);
    border-color: var(--euro2020Theme);
  }

  &.hive_body {
    background-image: none !important;

    .btn-red {
      background-color: var(--euro2020Theme) !important;
      border-color: var(--euro2020Theme) !important;
    }

    h2.h2-mb, p.lead-condensed {
      color: var(--euro2020Theme) !important;
    }
  }
}